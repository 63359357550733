import create from 'zustand';
import { Kid, KidModify } from '../models/family';
import { RepositoryStore } from '.';
import apiClient from '../services/apiClient';

export interface KidsStore extends RepositoryStore<Kid, KidModify> {
    ensured: boolean;
    ensure: () => Promise<void>;
    update: (kidId: string, kid: KidModify) => Promise<Kid>;
}

const useKidsStore = create<KidsStore>((set, get) => ({
    items: [],
    add: async (kid: KidModify) => {
        const result = await apiClient.callApi<Kid>({
            url: '/kids',
            method: 'POST',
            requestData: kid
        });
        await get().fetch();
        return result;
    },
    fetching: false,
    fetch: async () => {
        if (get().fetching) {
            return;
        }
        set(() => ({ fetching: true }));

        try {
            const result = await apiClient.callApi<Kid[]>({ url: '/kids' });
            set(() => ({
                fetchedOn: new Date(),
                fetching: false,
                items: result
            }));
        } catch (error) {
            set(() => ({ fetching: false }));
        }
    },
    ensured: false,
    ensure: async () => {
        if (get().ensured) {
            return;
        }
        set(() => ({ ensured: true }));
        get().fetch();
    },
    update: async (kidId: string, kid: KidModify) => {
        const result = await apiClient.callApi<Kid>({
            url: `/kids/${kidId}`,
            method: 'PUT',
            requestData: kid
        });
        get().fetch();
        return result;
    }
}));

export { useKidsStore };
