import * as React from 'react';
import { InputBase, Button } from '@material-ui/core';
import { useState } from 'react';

interface OwnProps {
    className?: string;
    searchSubmitted: (query: string) => void;
}

export default ({ className, searchSubmitted }: OwnProps) => {
    const [inputText, setInputText] = useState('');
    const [submittedText, setSubmittedText] = useState(inputText);
    const [focused, setFocused] = useState(false);

    return (
        <form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                setSubmittedText(inputText);
                searchSubmitted(inputText);
            }}
            autoComplete='off'>
            <InputBase
                fullWidth
                placeholder='Search memories'
                autoFocus
                value={inputText}
                onChange={(e) => setInputText(e.currentTarget.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                autoComplete='disable'
                endAdornment={
                    (inputText && (submittedText !== inputText) && (
                        <Button
                            variant='outlined'
                            size='small'
                            color='primary'
                            type='submit'
                            style={{ whiteSpace: 'nowrap', textTransform: 'initial' }}>
                            {focused ? 'Hit Enter' : 'Search'}
                        </Button >))}
                classes={{ input: className }} />
        </form>
    );
};
