import * as React from 'react';
import { Link, Typography } from '@material-ui/core';

const NotFoundPage = () => {
    return (
        <div>
            <Typography component='h1' variant='h3'>Error</Typography>
            <Typography component='p' variant='subtitle1'>
                Something didn't work as expected. Try <Link href='/'>reloading the app</Link>.</Typography>
        </div>
    );
};

export default NotFoundPage;
