import * as React from 'react';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { useHistory, useParams } from 'react-router-dom';
import { useIsMounted, useTitle } from '../../common/hooks';
import { useEffect, useState } from 'react';
import { Kid, KidMemory } from '../../models/family';
import apiClient from '../../services/apiClient';
import { useKidsStore } from '../../stores/kidsStore';
import MemoryForm from '../../components/kids/memories/MemoryForm';

export default () => {
    const { memoryId, returnUrl } = useParams<{ memoryId?: string; returnUrl?: string }>();
    const [kid, setKid] = useState<Kid | null>(null);
    const [memory, setMemory] = useState<KidMemory | null>(null);
    const addNotification = useNotificationsStore((state) => state.add);
    const kids = useKidsStore((s) => s.items);
    const history = useHistory();
    const mounted = useIsMounted();

    useEffect(
        () => {
            if (memoryId) {
                apiClient
                    .callApi<KidMemory>({ url: `/memories/${memoryId}` })
                    .then((m) => {
                        setMemory(m);
                        setKid(kids.find((f) => f.kidId === m.kidId)!);
                    })
                    .catch((e) => {
                        if (e.status === 404) {
                            mounted.current && history.push(`/not-found`);
                        } else {
                            addNotification('Failed to get memory details. Please try again later.');
                        }
                    });
            }
        },
        [memoryId, addNotification, history, mounted, kids]);

    useTitle(kid ? `Edit ${kid?.name}'s memory` : null, [kid]);

    return (
        memory && <MemoryForm
            initialModel={memory}
            apiCall={(m) => apiClient
                .callApi<KidMemory>({
                    url: `/memories/${memory.memoryId}`,
                    method: 'PUT',
                    requestData: m
                })}
            error={() => addNotification('There was a problem modifying this memory. Please try again later.')}
            success={(m) => mounted.current && history.push(returnUrl ?? `/kids/${m.kidId}`)}
            onCancel={() => mounted.current && history.push(returnUrl ?? `/kids/${kid?.kidId}`)} />
    );
};
