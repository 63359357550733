import moment from 'moment';

export const friendlyAge = (birthday: string | null, toDate: string | null = null, short = false) => {
    if (!birthday) {
        return null;
    }
    const birth = moment(birthday);
    const now = toDate ? moment(toDate) : moment();
    const years = now.diff(birth, 'years');
    const months = now.subtract(years, 'years').diff(birth, 'months');
    const days = now.subtract(months, 'months').diff(birth, 'days');

    const result = [];

    if (years > 0) {
        result.push(`${years}${short ? 'y' : years > 1 ? ' years' : ' year'} `);
    }
    if (months > 0) {
        result.push(`${months}${short ? 'm' : months > 1 ? ' months' : ' month'} `);
    }
    if (days > 0) {
        result.push(`${days}${short ? 'd' : days > 1 ? ' days' : ' day'} `);
    }

    switch (result.length) {
        case 1:
            return result[0];
        case 2:
            return `${result[0]} and ${result[1]}`;
        case 3:
            return `${result[0]}, ${result[1]} and ${result[2]}`;
        default:
            return '';
    }
};

export const friendlyFromNow = (birth: string | null, toDate: string | null = null) => {
    if (!birth) {
        return null;
    }
    const now = toDate ? moment(toDate) : moment();
    const nextBirthDay = moment(birth).year(now.year() + (moment(birth).year(now.year()).isBefore(now) ? 1 : 0));

    return nextBirthDay.from(now);
};

