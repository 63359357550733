import { Container, AppBar, Toolbar, Typography, makeStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import Spinner from '../Spinner';
import { useCommonStore } from '../../stores/commonStore';

export default ({ children }: PropsWithChildren<{}>) => {
    const classes = useStyles();
    const title = useCommonStore((state) => state.title);

    return (
        <div className={classes.root}>
            <AppBar position='absolute' className={classes.appBar}>
                <Toolbar>
                    <Typography component='h1' variant='h6' color='inherit' noWrap>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Spinner />
                <Container className={classes.container} maxWidth='lg'>
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                </Container>
            </main>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        position: 'relative'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBar: {
        backgroundColor: '#33691e',
        color: '#fff'
    }
}));
