import * as React from 'react';
import { getKidMemoryImageUrl } from '../../../models/family';
import { ButtonBase, makeStyles } from '@material-ui/core';
import { getFilePreview } from '../../../common';
import { useState, useEffect } from 'react';
import { useIsMounted } from '../../../common/hooks';

export interface MediaFile {
    fileId: string;
    local?: File;
}
interface OwnProps {
    files: MediaFile[];
    onImageClick?: (file: MediaFile) => void;
}

export default ({ files, onImageClick }: OwnProps) => {
    const classes = useStyles();
    const isMounted = useIsMounted();
    const [urls, setUrls] = useState<(string | null)[]>([]);

    useEffect(() => {
        Promise
            .all(
                files.map((f) => f.local
                    ? getFilePreview(f.local)
                    : Promise.resolve(getKidMemoryImageUrl(f.fileId, files.length > 2 ? 0 : 1))))
            .then((r) => isMounted.current && setUrls(r));
    }, [files, isMounted]);

    return (
        <ul className={classes.media}>
            {
                files.map((f, idx) => (
                    <li key={f.fileId}>
                        {
                            onImageClick
                                ? (<ButtonBase
                                    focusRipple
                                    className='at-image'
                                    onClick={() => onImageClick?.(f)}
                                    style={{ backgroundImage: `url(${urls[idx]})` }}
                                />)
                                : (<div
                                    className='at-image'
                                    style={{ backgroundImage: `url(${urls[idx]})` }} />)
                        }
                    </li>
                ))
            }
        </ul>
    );
};

const useStyles = makeStyles(() => ({
    media: {
        height: 140,
        margin: 0,
        padding: 0,
        listStyle: 'none',
        display: 'flex',
        '& li': {
            flexGrow: 1,
            flexBasis: 0,
            position: 'relative'
        },
        '& .at-image': {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',

            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
}));
