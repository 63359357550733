import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

interface OwnProps {
    content: React.ReactNode;
    onOK: () => void;
    onCancel: () => void;
    open?: boolean;
}

export default (props: OwnProps) => (
    <Dialog
        maxWidth='sm'
        aria-labelledby='confirmation-dialog-title'
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted={false}
        open={!!props.open}>
        <DialogTitle id='confirmation-dialog-title'>Confirm</DialogTitle>
        <DialogContent >
            {props.content}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onOK} color='primary' variant='contained' tabIndex={0}>Ok</Button>
            <Button onClick={props.onCancel} color='default' tabIndex={1}>Cancel</Button>
        </DialogActions>
    </Dialog>
);
