import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { InviteView, Paged } from '../models/common';
import apiClient from '../services/apiClient';
import { useNotificationsStore } from '../stores/notificationsStore';
import Pagination from '@material-ui/lab/Pagination';
import { useIsMounted } from '../common/hooks';
import {
    Button,
    Grid, Typography,
} from '@material-ui/core';
import InvitesTable from '../components/user/InvitesTable';
import { useKidsStore } from '../stores/kidsStore';
import AddIcon from '@material-ui/icons/Add';
import PromptDialog from '../components/common/PromptDialog';
import { useLocation } from 'react-router-dom';

const InvitesPagePage = () => {
    const addNotification = useNotificationsStore((state) => state.add);
    const isMounted = useIsMounted();
    const [invitesFromMe, setInvitesFromMe] = useState<Paged<InviteView> | undefined>();
    const [invitesToMe, setInvitesToMe] = useState<Paged<InviteView> | undefined>();
    const kidsFetch = useKidsStore((s) => s.fetch);
    const [inviteOpened, setInviteOpened] = useState(false);
    const [inviteSending, setInviteSending] = useState(false);
    const inviteId = new URLSearchParams(useLocation().search).get('inviteId');

    const fetchInvitesFromMe = useCallback(
        (page: number) => apiClient
            .callApi<Paged<InviteView>>({ url: `/invites?pageIndex=${page}` })
            .then((r) => isMounted.current && setInvitesFromMe(r))
            .catch(() => addNotification('Something went wrong and we didn\'t get invites. Try reloading the page.')),
        [addNotification, isMounted]);

    const fetchInvitesToMe = useCallback(
        (page: number) => apiClient
            .callApi<Paged<InviteView>>({ url: `/invites/tome?pageIndex=${page}&status=Waiting&status=Accepted&status=Rejected` })
            .then((r) => isMounted.current && setInvitesToMe(r))
            .catch(() => addNotification('Something went wrong and we didn\'t get invites. Try reloading the page.')),
        [addNotification, isMounted]);

    useEffect(
        () => void ((isMounted.current && fetchInvitesToMe(0)) || (!inviteId && fetchInvitesFromMe(0))),
        [isMounted, fetchInvitesFromMe, fetchInvitesToMe, inviteId]);

    const sendInvite = useCallback(
        (value: string | null) => {
            setInviteSending(true);
            setInviteOpened(false);

            apiClient
                .callApi<InviteView>({
                    url: `/invites`,
                    method: 'POST',
                    requestData: {
                        entityType: 'App',
                        recipientEmail: value
                    }
                })
                .then(() => fetchInvitesFromMe(invitesFromMe?.currentPage ?? 0))
                .catch(() => addNotification(`Something went wrong and we couldn't send this invite`))
                .then(() => isMounted.current && setInviteSending(false));
        },
        [isMounted, fetchInvitesFromMe, addNotification, invitesFromMe]);
    return (
        <Grid container direction='column' spacing={2}>
            {
                !inviteId && (
                    <Grid item>
                        <Grid container justify='space-between'>
                            <Grid item><Typography variant='h6'>Invites sent by you</Typography></Grid>
                            <Grid item>
                                <Button
                                    variant='text'
                                    color='primary'
                                    disabled={inviteOpened || inviteSending}
                                    onClick={() => setInviteOpened(true)}
                                    startIcon={<AddIcon />}>Invite</Button>
                                <PromptDialog
                                    open={inviteOpened}
                                    title={`Invite to Atrict`}
                                    initialValue={null}
                                    required
                                    email
                                    placeholder={'example@example.com'}
                                    onCancel={() => setInviteOpened(false)}
                                    onOK={sendInvite} />
                            </Grid>
                        </Grid>
                        <InvitesTable
                            invites={invitesFromMe?.items}
                            mainColumn='To'
                            ariaLabel='Invites sent by you'
                            onCancel={({ inviteId, recipientEmail }) => apiClient
                                .callApi<InviteView>({
                                    url: `/invites/${inviteId}/cancel`,
                                    method: 'POST'
                                })
                                .then(() => {
                                    addNotification(`Invite to ${recipientEmail} was canceled`);
                                    fetchInvitesFromMe(invitesFromMe?.currentPage ?? 0);
                                })
                                .catch(() => addNotification(`There was a problem canceling invite to ${recipientEmail}. Try reloading the page.`))} />
                    </Grid>
                )
            }
            {
                invitesFromMe && invitesFromMe.total > invitesFromMe.pageSize && (
                    <Grid item container justify='center'>
                        <Pagination
                            variant='outlined'
                            color='secondary'
                            count={Math.ceil(invitesFromMe.total / invitesFromMe.pageSize)}
                            page={invitesFromMe.currentPage + 1}
                            onChange={(_, p) => fetchInvitesFromMe(p - 1)} />
                    </Grid>
                )
            }
            <Grid item>
                <Typography variant='h6'>Invites sent to you</Typography>
                <InvitesTable
                    invites={invitesToMe?.items}
                    mainColumn='From'
                    ariaLabel='Invites sent to you'
                    onAccept={({ inviteId, fromUser }) => apiClient
                        .callApi<InviteView>({
                            url: `/invites/${inviteId}/accept`,
                            method: 'POST'
                        })
                        .then(({ entityType }) => {
                            entityType === 'Kid' && kidsFetch();
                            fetchInvitesToMe(invitesToMe?.currentPage ?? 0);
                        })
                        .catch(() => addNotification(`There was a problem accepting invite from ${fromUser?.name ?? 'another user'}. Try reloading the page.`))}
                    onReject={({ inviteId, fromUser }) => apiClient
                        .callApi<InviteView>({
                            url: `/invites/${inviteId}/reject`,
                            method: 'POST'
                        })
                        .then(() => fetchInvitesToMe(invitesToMe?.currentPage ?? 0))
                        .catch(() => addNotification(`There was a problem rejecting invite from ${fromUser?.name ?? 'another user'}. Try reloading the page.`))} />
            </Grid>
            {
                invitesToMe && invitesToMe.total > invitesToMe.pageSize && (
                    <Grid item container justify='center'>
                        <Pagination
                            variant='outlined'
                            color='secondary'
                            count={Math.ceil(invitesToMe.total / invitesToMe.pageSize)}
                            page={invitesToMe.currentPage + 1}
                            onChange={(_, p) => fetchInvitesToMe(p - 1)} />
                    </Grid>
                )
            }
        </Grid>
    );
};

export default InvitesPagePage;
