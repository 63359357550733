import React from 'react';
import ErrorPage from './../pages/ErrorPage';
import Page from '../pages/Page';
import MinLayout from '../layouts/minimal';

export class ErrorBoundary extends React.Component<{}, { hasError: false }> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error('Unhandled error occurred', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <Page layout={MinLayout} component={ErrorPage} title='Error' />;
        }

        return this.props.children;
    }
}
