import * as React from 'react';
import { Link } from '@material-ui/core';
import { useIsMounted } from '../../common/hooks';
import { useHistory } from 'react-router-dom';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { useEffect } from 'react';
import authService from '../../services/authService';

export default () => {

    const mounted = useIsMounted();
    const history = useHistory();
    const addNotification = useNotificationsStore((state) => state.add);

    useEffect(() => {
        if (!mounted.current) { return; }

        authService.signoutRedirectCallback()
            .catch(() => addNotification('Sorry, we couldn\'t log you out'));
    });

    return (
        <div>
            You've been logout.&nbsp;
            <Link href='#' onClick={() => authService.signinRedirect(
                { state: history.location.state })}>Click here</Link> to login back.
        </div>);
};
