import React, { Fragment, useCallback, useEffect } from 'react';
import {
    List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles
} from '@material-ui/core';
import shallow from 'zustand/shallow';
import { useCookbooksStore } from '../../stores/cookbooksStore';
import { useKidsStore } from '../../stores/kidsStore';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import KidAvatar from '../../components/kids/KidAvatar';

interface OwnProps {
    drawerOpened: boolean;
    onClick?: () => void;
}

export default ({ onClick, drawerOpened }: OwnProps) => {
    const classes = useStyles();
    const cookbooks = useCookbooksStore((s) => ({ items: s.items, ensure: s.ensure }), shallow);
    const kids = useKidsStore((s) => ({ items: s.items, ensure: s.ensure }), shallow);
    const history = useHistory();
    const isCurrent = useCallback((path: string) => history.location.pathname === path, [history]);
    useEffect(() => void (cookbooks.ensure() && kids.ensure()), [cookbooks, kids]);

    return (
        <Fragment>
            {!!cookbooks.items?.length &&
                (<List
                    className={clsx(classes.navMenu, !drawerOpened && classes.navMenuCompact)}
                    onClick={onClick}
                    subheader={(
                        <ListSubheader component='div'>
                            Recipes
                        </ListSubheader>
                    )}>
                    <ListItem button component={Link} to='/recipes' title='All Recipes'
                        className={clsx(isCurrent('/recipes') && 'active')}>
                        <ListItemIcon>
                            {drawerOpened
                                ? (<ImportContactsIcon />)
                                : (<MenuBookIcon />)}
                        </ListItemIcon>
                        <ListItemText primary='All Recipes' />
                    </ListItem>
                </List>)
            }
            {!!kids.items.length &&
                (<List
                    className={clsx(classes.navMenu, !drawerOpened && classes.navMenuCompact)}
                    onClick={onClick}
                    subheader={(
                        <ListSubheader component='div'>
                            Kids
                        </ListSubheader>
                    )}>
                    {
                        kids.items.map((k) => (
                            <ListItem key={k.kidId} button component={Link}
                                to={`/kids/${k.kidId}`} title={k.name}
                                className={clsx(isCurrent(`/kids/${k.kidId}`) && 'active')}>
                                <ListItemIcon>
                                    <KidAvatar kid={drawerOpened && !k.photoFileId ? undefined : k} />
                                </ListItemIcon>
                                <ListItemText primary={k.name} />
                            </ListItem>
                        ))
                    }
                </List>)
            }
        </Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    navMenu: {
        padding: 0,
        '& .MuiListSubheader-root, & .MuiListItemText-root span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& .MuiListItem-root.active': {
            backgroundColor: 'rgba(255, 255, 255, 0.16)'
        },
        '& .MuiListSubheader-root': {
            transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        }
    },
    navMenuCompact: {
        '& .MuiListSubheader-root': {
            padding: '0 13px',
            transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        }
    }
}));
