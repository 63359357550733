import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useCookbooksStore } from '../../stores/cookbooksStore';
import shallow from 'zustand/shallow';
import { useMemo, useEffect, useState } from 'react';
import { Paged, EmptyPaged } from '../../models/common';
import apiClient from '../../services/apiClient';
import { useNotificationsStore } from '../../stores/notificationsStore';
import RecipeSummaryList from '../../components/cooking/RecipeSummaryList';
import Pagination from '@material-ui/lab/Pagination';
import Link from '../../components/Link';
import { RecipeSummary } from '../../models/cooking';
import { useNotFound, useTitle } from '../../common/hooks';
import { Grid } from '@material-ui/core';

const ListRecipesPage = () => {
    const { cookbookId } = useParams<{ cookbookId?: string }>();
    const addNotification = useNotificationsStore((state) => state.add);
    const cookbooks = useCookbooksStore(
        (s) => ({ items: s.items, fetch: s.fetch, fetching: s.fetching }),
        shallow);
    const [recipes, setRecipes] = useState<Paged<RecipeSummary> | undefined>();
    const [currentPage, setCurrentPage] = useState(0);

    const currentCookBook = useMemo(() => {
        if (cookbooks.fetching) {
            // just wait
            return undefined;
        }

        if (cookbooks.items.length === 0) {
            cookbooks.fetch();
            return undefined;
        }

        if (!cookbookId) {
            return cookbooks.items[0];
        }

        // null indicates that cookbooks fetched, but item's ID wasn't found
        return cookbooks.items.find((c) => c.cookbookId === cookbookId) ?? null;
    }, [cookbookId, cookbooks]);

    useNotFound(() => currentCookBook === null, [currentCookBook]);

    useTitle(
        currentCookBook && cookbooks.items.length > 1
            ? `Recipes of ${currentCookBook.name}`
            : 'Recipes',
        [currentCookBook, cookbooks]);

    useEffect(() => {
        currentCookBook && apiClient
            .callApi<Paged<RecipeSummary>>({
                url: `/cookbooks/${currentCookBook.cookbookId}/recipes?pageIndex=${currentPage}`
            })
            .then(setRecipes)
            .catch(() => {
                addNotification('Something went wrong and we didn\'t get recipes. Try reloading the page.');
                setRecipes(EmptyPaged);
            });
    }, [setRecipes, currentCookBook, addNotification, currentPage]);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                <RecipeSummaryList recipes={recipes?.items} />
            </Grid>
            {
                recipes && recipes.total > recipes.pageSize && (
                    <Grid item container justify='center'>
                        <Pagination
                            variant='outlined'
                            color='secondary'
                            count={Math.ceil(recipes.total / recipes.pageSize)}
                            page={recipes.currentPage + 1}
                            onChange={(_, p) => setCurrentPage(p - 1)} />
                    </Grid>
                )
            }
            {
                recipes && recipes.total === 0 && <div>
                    No recipes found. You can add new one&nbsp;
                    <Link url={`/${currentCookBook?.cookbookId}/recipes/new`}>here</Link>.
                    </div>
            }
        </Grid>
    );
};



export default ListRecipesPage;
