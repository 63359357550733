import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useNotificationsStore } from '../../stores/notificationsStore';
import RecipeForm from '../../components/cooking/RecipeForm';
import apiClient from '../../services/apiClient';
import { Recipe } from '../../models/cooking';
import { useIsMounted, useQuery } from '../../common/hooks';

const NewRecipePage = () => {
    const query = useQuery();
    const addNotification = useNotificationsStore((state) => state.add);
    const history = useHistory();
    const mounted = useIsMounted();

    return (
        <RecipeForm
            cookbookId={query.get('cookbookId') ?? undefined}
            apiCall={(m) => apiClient
                .callApi<Recipe>({
                    url: `/cookbooks/${m.cookbookId}/recipes`,
                    method: 'POST',
                    requestData: m
                })}
            error={() => addNotification('There was a problem adding a recipe. Please try again later.')}
            success={(m) => mounted.current && history.push(`/recipes/${m.recipeId}`)}
        />
    );
};

export default NewRecipePage;
