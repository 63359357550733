import { UserManagerSettings, WebStorageStateStore, UserManager, Log } from 'oidc-client';
import { appHost, idpHost, environment } from '../common/config';
import { userStoreApi, UserStore } from '../stores/userStore';
import { StoreApi } from 'zustand';

export const authRoutes = {
    loginCallback: '/auth/callback-login',
    logoutCallback: '/auth/callback-logout',
    silentRenew: '/silent_renew.html'
};

const userManagerConfig: UserManagerSettings = {
    client_id: 'webapp',
    redirect_uri: `${appHost}${authRoutes.loginCallback}`,
    silent_redirect_uri: `${appHost}${authRoutes.silentRenew}`,
    post_logout_redirect_uri: `${appHost}${authRoutes.logoutCallback}`,
    response_type: 'id_token token',
    scope: 'openid profile api.public',
    authority: idpHost,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.sessionStorage })
};


export class AuthService {
    private readonly userManager: UserManager;

    constructor(
        config: UserManagerSettings,
        private readonly store: StoreApi<UserStore>) {
        this.userManager = new UserManager(config);

        if (environment === 'development') {
            Log.logger = console;
            Log.level = Log.DEBUG;
        }

        this.userManager.events.addSilentRenewError((e) => {
            console.error('Error during silent renew', e);
        });

        this.userManager.events.addAccessTokenExpired(() => {
            console.log('Access token expired => renewing');
            this.signinSilent();
        });

        this.userManager.events.addUserLoaded((user) => this.store.setState({ user }));
        this.userManager.events.addUserUnloaded(() => this.store.setState({ user: null }));
    }

    public getUser = () => this.userManager
        .getUser()
        .then((user) => {
            this.store.setState({ user });
            return user;
        })
        .catch((e) => {
            console.error('Failed to retrieve user', e);
            return Promise.reject(e);
        })

    public signinRedirect = (args?: any) => this.userManager
        .signinRedirect(args)
        .catch((e) => {
            console.error('Failed to redirect during sign-in to OIDC service', e);
            return Promise.reject(e);
        })

    public signoutRedirect = (args?: any) => this.userManager
        .signoutRedirect(args)
        .catch((e) => {
            console.error('Failed to redirect during sign-out to OIDC service', e);
            return Promise.reject(e);
        })

    public signinRedirectCallback = (url?: string) => this.userManager
        .signinRedirectCallback(url)
        .catch((e) => {
            console.error('Failed to handle callback during sign-in from OIDC service', e);
            return Promise.reject(e);
        })

    public signoutRedirectCallback = (url?: string) => this.userManager
        .signoutRedirectCallback(url)
        .catch((e) => {
            console.error('Failed to handle callback during sign-out from OIDC service', e);
            return Promise.reject(e);
        })

    private signinSilent = () => {
        this.userManager.signinSilent()
            .then((user) => {
                console.log('User signed in', user);
            })
            .catch((err) => {
                if (err === 'login_required') {
                    return this.signinRedirect();
                } else {
                    console.error('Error during user sign in', err);
                }
            });
    }
}

const authService = new AuthService(userManagerConfig, userStoreApi);
export default authService;
