import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FormRender } from '../../models/common';
import {
    Grid, TextField, Button, InputAdornment
} from '@material-ui/core';
import {
    compose, required, minLength, maxLength, submit, numberOnly,
    minNumber
} from '../../common/validation';
import SaveIcon from '@material-ui/icons/Save';
import { KidModify, Kid, KID_IMAGE } from '../../models/family';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CakeIcon from '@material-ui/icons/Cake';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ExpandableFields from '../form/ExpandableFields';
import FilePicker from '../common/FilePicker';
import { WeightPicker, HeightPicker } from './MetricPickers';

type Props = FormRender<KidModify, Kid>;
const nameValidators = compose(required(), minLength(1), maxLength(50));
const birthWeightValidators = compose(numberOnly('Must be a valid weight in kilograms'), minNumber(0.1));
const birthHeightValidators = compose(numberOnly('Must be a valid heigh in centimeters'), minNumber(1));
export default ({ initialModel, ...contract }: Props) => {
    const [uploading, setUploading] = React.useState(false);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form
                initialValues={initialModel}
                onSubmit={(v: KidModify) => submit({ submitModel: v, ...contract })}
                mutators={{
                    resetMetric: (_, state, utils) => utils.changeValue(state, 'birthMetric', () => null)
                }}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => (
                    <form noValidate onSubmit={handleSubmit} autoComplete='off'>
                        <Grid container spacing={4} direction='column'>
                            <Grid item xs={12}>
                                <Field name='name' validate={nameValidators}>
                                    {({ input, meta }) => (
                                        <TextField
                                            label='Name'
                                            margin='normal'
                                            placeholder='Kid name'
                                            fullWidth
                                            autoFocus={!initialModel}
                                            error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                            helperText={(meta.dirty && meta.error) || meta.submitError}
                                            disabled={meta.submitting}
                                            {...input} />
                                    )}
                                </Field>
                                <Field name='birthDate'>
                                    {({ input, meta }) => (
                                        <DatePicker
                                            label='Birthdate'
                                            margin='normal'
                                            disableFuture
                                            autoOk
                                            clearable
                                            value={input.value ? moment(input.value) : null}
                                            helperText={(meta.dirty && meta.error) || meta.submitError}
                                            error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                            fullWidth
                                            disabled={meta.submitting}
                                            onChange={(date) => input.onChange(date?.toISOString() ?? null)}
                                            labelFunc={(date) => date?.format('LL') || ''}
                                            placeholder={`Kid's date of birth`}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'><CakeIcon /></InputAdornment>
                                                )
                                            }} />
                                    )}
                                </Field>
                                <Field name='photoFileId'>
                                    {({ input, meta }) => (
                                        <FilePicker
                                            label={`Kid's photo`}
                                            placeholder='Select a file'
                                            error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                            helperText={(meta.dirty && meta.error) || meta.submitError}
                                            disabled={meta.submitting}
                                            fileType='Image'
                                            imageProcessing={KID_IMAGE}
                                            value={input.value}
                                            onStateChange={(busy) => setUploading(busy)}
                                            onFileUploaded={(f) => input.onChange(f.fileId)}
                                            onFileRemoved={() => input.onChange(null)} />
                                    )}
                                </Field>
                                <ExpandableFields
                                    defaultExpanded={!!(
                                        initialModel?.birthMetric?.height ||
                                        initialModel?.birthMetric?.weight)}
                                    onClose={form.mutators.resetMetric}
                                    title='Birth Metrics'>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Field name='birthMetric.weight' validate={birthWeightValidators}>
                                                {({ input, meta }) => (
                                                    <WeightPicker
                                                        label='Weight'
                                                        placeholder='Birth weight'
                                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                                        disabled={meta.submitting}
                                                        onChange={input.onChange}
                                                        value={input.value} />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name='birthMetric.height' validate={birthHeightValidators}>
                                                {({ input, meta }) => (
                                                    <HeightPicker
                                                        label='Height'
                                                        placeholder='Birth height'
                                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                                        disabled={meta.submitting}
                                                        onChange={input.onChange}
                                                        value={input.value} />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </ExpandableFields>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={submitting || pristine || hasValidationErrors || uploading}
                                    startIcon={<SaveIcon />}>{initialModel ? 'Save' : 'Create'}</Button>
                            </Grid>
                        </Grid>
                    </form>
                )} />
        </MuiPickersUtilsProvider>
    );
};

