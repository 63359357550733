import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PlusIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FaceIcon from '@material-ui/icons/Face';
import { Fab, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useCookbooksStore } from '../../stores/cookbooksStore';
import shallow from 'zustand/shallow';
import { useHistory, Link } from 'react-router-dom';

interface OwnProps {
    onClick: () => void;
    homeOnly?: boolean;
}

export default ({ homeOnly, onClick }: OwnProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const cookbooks = useCookbooksStore((s) => ({ items: s.items, ensure: s.ensure }), shallow);
    const history = useHistory();
    useEffect(() => void homeOnly && cookbooks.ensure(), [homeOnly, cookbooks]);

    return (
        <div className={classes.root}>
            <Fab
                className={classes.button}
                aria-label={homeOnly ? 'home' : 'create'}
                size='small'
                variant='extended'
                aria-owns={anchorEl ? 'menu-actions' : undefined}
                aria-haspopup='true'
                onClick={(e) => {
                    if (homeOnly) { history.push('/'); onClick(); } else { setAnchorEl(e.currentTarget); }
                }}>
                {
                    homeOnly
                        ? (<React.Fragment><HomeIcon /> <span>Home</span></React.Fragment>)
                        : (<React.Fragment><PlusIcon /> <span>Create</span></React.Fragment>)
                }
            </Fab>
            {
                !homeOnly && (
                    <Menu
                        id='menu-actions'
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={anchorEl !== null}
                        onClose={() => setAnchorEl(null)}
                        onClick={onClick}
                        classes={{
                            list: classes.list,
                            paper: classes.paper
                        }}>
                        <MenuItem onClick={() => setAnchorEl(null)} button component={Link} to='/kids/new'>
                            <ListItemIcon>
                                <FaceIcon />
                            </ListItemIcon>
                            <ListItemText primary='Add a kid' />
                        </MenuItem>
                        {
                            !!cookbooks.items?.length &&
                            (<MenuItem onClick={() => setAnchorEl(null)} button component={Link} to='/recipes/new'>
                                <ListItemIcon>
                                    <NoteAddIcon />
                                </ListItemIcon>
                                <ListItemText primary='Add a recipe' />
                            </MenuItem>)
                        }
                    </Menu>
                )
            }
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 0 8px'
    },
    button: {
        '.a-drawer-opened &': {
            margin: '0 16px',
            width: '100%'
        },
        '&.MuiFab-extended.MuiFab-sizeSmall': {
            padding: '0 5px'
        },
        '& .MuiFab-label span': {
            width: 0,
            opacity: 0,
            transition: theme.transitions.create(['width', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        },
        '.a-drawer-opened & .MuiFab-label span': {
            width: 'auto',
            opacity: 1,
            transition: theme.transitions.create(['width', 'opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            })
        }
    },
    paper: {
        width: 250
    },
    list: {
        '& .MuiListItemText-root p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    }
}));
