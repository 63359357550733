import * as React from 'react';
import { makeStyles, Theme, Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import MemoryInline, { MemoryInlineLoading } from './MemoryInline';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { Kid, KidMemory, getKidMemoryImageUrl } from '../../../models/family';
import MemoryInlineAdd from './MemoryInlineAdd';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { Paged } from '../../../models/common';
import { useIsMounted } from '../../../common/hooks';
import apiClient from '../../../services/apiClient';
import { useNotificationsStore } from '../../../stores/notificationsStore';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';
import { useUserStore } from '../../../stores/userStore';
import ConfirmDialog from '../../common/ConfirmDialog';
import { Link } from 'react-router-dom';
import MemoryInlineEdit from './MemoryInlineEdit';
import { FilesMediaViewDialog } from '../../common/FilesMediaView';
import { MediaFile } from './MemoryFiles';
import { friendlyAge } from '../../../common/humanize';

const MemoryTimelineItem = (props: React.PropsWithChildren<{
    icon?: React.ReactNode;
    last?: boolean;
}>) => (
    <TimelineItem>
        <TimelineSeparator>
            {
                props.icon && (
                    <TimelineDot variant='outlined' color='secondary'>
                        {props.icon}
                    </TimelineDot>
                )
            }
            {!props.last && (<TimelineConnector />)}
        </TimelineSeparator>
        <TimelineContent>{props.children}</TimelineContent>
    </TimelineItem>
);

export default ({ kid }: { kid: Kid }) => {
    const classes = useStyles();
    const todayAge = useMemo(() => friendlyAge(kid.birthDate), [kid]);
    const isMounted = useIsMounted();
    const [items, setItems] = useState<KidMemory[]>([]);
    const [itemsMode, setItemsMode] = useState<'timeline' | 'search'>('timeline');
    const [lastResult, setLastResult] = useState<Paged<KidMemory> | null>(null);
    const addNotification = useNotificationsStore((state) => state.add);
    const [fetching, setFetching] = useState(false);
    const user = useUserStore((state) => state.user);
    const [anchorForSettings, setAnchorForSettings] = useState<null | [HTMLElement, KidMemory]>(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [memoryForEdit, setMemoryForEdit] = useState<KidMemory | null>(null);
    const [memoryForFilesView, setMemoryForFilesView] = useState<[KidMemory, MediaFile | null] | null>(null);

    const fetchNext = useCallback(() => {
        setFetching(true);
        apiClient
            .callApi<Paged<KidMemory>>({
                url: `/kids/${kid.kidId}/memories?pageIndex=${lastResult ? lastResult.currentPage + 1 : 0}&limit=30`
            })
            .then((r) => {
                if (!isMounted.current) { return; }
                if (lastResult?.currentPage === r.currentPage) { return; }

                setLastResult(r);
                setItems((items) => ([...items, ...r.items]));
            })
            .catch(() => addNotification(`We are sorry, but weren't able to get all results.`))
            .then(() => isMounted.current && setFetching(false));
    }, [lastResult, isMounted, addNotification, kid.kidId]);

    // initial load of kid
    useEffect(() => {
        setLastResult(null);
        setItems([]);
        setItemsMode('timeline');
    }, [kid]);

    // fetch first page results
    useEffect(
        () => void (!lastResult && itemsMode === 'timeline' && isMounted.current && fetchNext()),
        [isMounted, lastResult, fetchNext, itemsMode]);

    const memoryAddedCallback = useCallback((newMemory: KidMemory) => {
        if (items.length === 0) {
            setItems([newMemory]);
            return;
        }
        const newDate = moment(newMemory.occurredOn);
        for (let i = 0; i < items.length; i++) {
            if (moment(items[i].occurredOn).isBefore(newDate)) {
                items.splice(i, 0, newMemory);
                setItems([...items]);
                return;
            }
        }
    }, [items]);

    const memorySearchCallback = useCallback((query?: string) => {
        setItems([]);
        if (!query) {
            setLastResult(null);
            setItemsMode('timeline');
            return;
        }
        setItemsMode('search');
        setFetching(true);
        apiClient
            .callApi<KidMemory[]>({
                url: `/kids/${kid.kidId}/memories/search?query=${query}`
            })
            .then((r) => {
                if (!isMounted.current) { return; }
                setItems(r);
            })
            .catch(() => addNotification(`We are sorry, but weren't able to get all results.`))
            .then(() => isMounted.current && setFetching(false));
    }, [addNotification, isMounted, kid.kidId]);

    const memoryUpdatedCallback = useCallback((updated: KidMemory) => {
        const newDate = moment(updated.occurredOn);
        const originalItem = items.find(
            (m) => m.memoryId === updated.memoryId)!;
        if (newDate.isSame(originalItem.occurredOn)) {
            setItems((items) => items.map(
                (m) => m.memoryId === updated.memoryId ? updated : m));
        } else if (newDate.isBefore(items[items.length - 1].occurredOn)) {
            // moved below the last retrieved item => do nothing
        } else if (newDate.isAfter(items[0].occurredOn)) {
            // push into beginning
            setItems((items) =>
                [updated, ...items.filter(
                    (i) => i.memoryId !== updated.memoryId)]);
        } else {
            // find a spot in between
            const clean = items.filter((i) => i.memoryId !== updated.memoryId);
            for (let i = 0; i < clean.length; i++) {
                if (moment(clean[i].occurredOn).isBefore(newDate)) {
                    clean.splice(i, 0, updated);
                    setItems(clean);
                    break;
                }
            }
        }
        setMemoryForEdit(null);
    }, [items]);

    return (
        <React.Fragment>
            <Timeline align='left' className={classes.timeline}>
                <MemoryTimelineItem
                    icon={<PlaylistAddIcon color='secondary' />}
                    last={itemsMode === 'search' && !fetching && items.length === 0}>
                    <MemoryInlineAdd
                        kidId={kid.kidId}
                        onAdded={user?.profile.sub === kid.ownerUserId ? memoryAddedCallback : undefined}
                        onSearch={memorySearchCallback} />
                </MemoryTimelineItem>
                {
                    todayAge && itemsMode === 'timeline' && (
                        <MemoryTimelineItem icon={<TodayOutlinedIcon color='secondary' />}>
                            <Paper className={classes.paper}>Today {kid.name} is {todayAge}</Paper>
                        </MemoryTimelineItem>
                    )
                }
                {
                    items.map((m, i) => (
                        <MemoryTimelineItem
                            key={m.memoryId}
                            last={itemsMode === 'search' && i === items.length - 1}
                            icon={
                                m.files.length
                                    ? (<PhotoLibraryOutlinedIcon color='secondary' />)
                                    : m.metric
                                        ? (<AssessmentOutlinedIcon color='secondary' />)
                                        : (<ChatOutlinedIcon color='secondary' />)}>
                            {
                                memoryForEdit?.memoryId === m.memoryId
                                    ? (
                                        <MemoryInlineEdit
                                            model={memoryForEdit}
                                            onUpdated={memoryUpdatedCallback}
                                            onCancel={() => setMemoryForEdit(null)} />
                                    )
                                    : (
                                        <MemoryInline
                                            memory={m}
                                            kid={kid}
                                            onSettingsClick={user?.profile.sub === m.ownerUserId
                                                ? (m, e) => setAnchorForSettings([e, m])
                                                : undefined}
                                            onAlbumClick={(m, f) => setMemoryForFilesView([m, f ?? null])} />
                                    )
                            }
                        </MemoryTimelineItem>
                    ))
                }
                {
                    fetching && (
                        <MemoryTimelineItem
                            icon={<HourglassEmptyIcon color='secondary' />}
                            last={itemsMode === 'search'}>
                            <MemoryInlineLoading />
                        </MemoryTimelineItem>
                    )
                }
                {
                    !fetching &&
                    itemsMode === 'timeline' &&
                    lastResult && items && lastResult.total > items.length && (
                        <MemoryTimelineItem
                            icon={<HourglassEmptyIcon color='secondary' />}>
                            <Button
                                variant='outlined'
                                size='small'
                                color='primary'
                                onClick={fetchNext}>Load More...</Button>
                        </MemoryTimelineItem>
                    )
                }
                {
                    itemsMode === 'timeline' && (
                        <MemoryTimelineItem icon={<CakeOutlinedIcon color='secondary' />} last>
                            <Paper className={classes.paper}>{kid.name} was born</Paper>
                        </MemoryTimelineItem>
                    )
                }
            </Timeline>
            <Menu
                id='menu-settings'
                anchorEl={anchorForSettings?.[0]}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                open={anchorForSettings !== null}
                onClose={() => setAnchorForSettings(null)}>
                <MenuItem
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        setMemoryForEdit(anchorForSettings?.[1]!);
                        setAnchorForSettings(null);
                    }}
                    button
                    component={Link}
                    to={`/memories/${anchorForSettings?.[1].memoryId}/edit`}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary='Edit' />
                </MenuItem>
                <MenuItem onClick={() => setConfirmDelete(true)}>
                    <ListItemIcon>
                        <DeleteForeverIcon />
                    </ListItemIcon>
                    <ListItemText primary='Delete' />
                </MenuItem>
            </Menu>
            <ConfirmDialog
                open={confirmDelete}
                content={`Are sure you want to permanently delete memory from ${anchorForSettings && moment(anchorForSettings[1].occurredOn).format('LL')}?`}
                onCancel={() => {
                    setConfirmDelete(false);
                    setAnchorForSettings(null);
                }}
                onOK={() => {
                    setConfirmDelete(false);
                    apiClient
                        .callApi<KidMemory>({
                            url: `/memories/${anchorForSettings?.[1].memoryId}`,
                            method: 'DELETE'
                        })
                        .then((m) => setItems((items) => items.filter((f) => f.memoryId !== m.memoryId)))
                        .catch((e) => addNotification('Failed to delete memory. Please try again later.'));
                    setAnchorForSettings(null);
                }} />
            {
                memoryForFilesView && (
                    <FilesMediaViewDialog
                        open={!!memoryForFilesView}
                        onClose={() => setMemoryForFilesView(null)}
                        fileUrlFactory={getKidMemoryImageUrl}
                        files={memoryForFilesView[0].files}
                        zoomFile={memoryForFilesView[1] ?? undefined}
                        onNameChanged={user?.profile.sub === memoryForFilesView[0].ownerUserId
                            ? (fileId, name) => apiClient
                                .callApi<KidMemory>({
                                    url: `/memories/${memoryForFilesView[0].memoryId}/files/${fileId}`,
                                    method: 'PUT',
                                    requestData: { name }
                                })
                                .then((m) => {
                                    setItems((items) =>
                                        ([...items.map((f) => f.memoryId === m.memoryId ? m : f)]));
                                    setMemoryForFilesView((old) => [
                                        m,
                                        old?.[1] ? m.files.find((f) => f.fileId === fileId) ?? null : null]);
                                })
                                .catch(() => addNotification('Failed to update name. Please try again later.'))
                            : undefined}
                        title={`Images from ${moment(memoryForFilesView[0].occurredOn).format('LL')}`}
                        onDeleteConfirmed={(fileId) =>
                            apiClient
                                .callApi<KidMemory>({
                                    url: `/memories/${memoryForFilesView[0].memoryId}/files/${fileId}`,
                                    method: 'DELETE'
                                })
                                .then((m) => {
                                    setItems((items) =>
                                        ([...items.map((f) => f.memoryId === m.memoryId ? m : f)]));
                                    setMemoryForFilesView([m, null]);
                                })
                                .catch(() => addNotification('Failed to delete file. Please try again later.'))
                        } />
                )
            }
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '6px 16px'
    },
    timeline: {
        padding: 0,
        margin: 0,
        '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none'
        }
    }
}));
