import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FormRender } from '../../models/common';
import { Grid, TextField, Button } from '@material-ui/core';
import { compose, required, minLength, maxLength, submit } from '../../common/validation';
import SaveIcon from '@material-ui/icons/Save';
import { RecipeModify, Recipe } from '../../models/cooking';
import CookbookSelect from './CookbookSelect';

interface OwnProps {
    cookbookId?: string;
}
type Props = OwnProps & FormRender<RecipeModify, Recipe>;
const nameValidators = compose(required(), minLength(1), maxLength(50));
const descriptionValidators = maxLength(1000);
export default ({ initialModel, cookbookId, ...contract }: Props) => {
    return (
        <Form
            initialValues={initialModel ? initialModel : cookbookId ? { cookbookId } : undefined}
            onSubmit={(v: RecipeModify) => submit({ submitModel: v, ...contract })}
            render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                <form noValidate onSubmit={handleSubmit} autoComplete='off'>
                    <Grid container spacing={1} direction='column'>
                        <Grid item md={4} sm={6} xs={12}>
                            <Field name='cookbookId'>
                                {({ input, meta }) => (
                                    <CookbookSelect
                                        label='Cookbook'
                                        margin='normal'
                                        fullWidth
                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                        disabled={meta.submitting}
                                        selectCookbookId={input.value || null}
                                        onSelected={input.onChange} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Field name='title' validate={nameValidators}>
                                {({ input, meta }) => (
                                    <TextField
                                        label='Recipe name'
                                        margin='normal'
                                        fullWidth
                                        autoFocus={!initialModel}
                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                        disabled={meta.submitting}
                                        {...input} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Field name='description' validate={descriptionValidators}>
                                {({ input, meta }) => (
                                    <TextField
                                        label='Description'
                                        margin='normal'
                                        fullWidth
                                        multiline
                                        rows={4}
                                        rowsMax={10}
                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                        disabled={meta.submitting}
                                        {...input} />
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                disabled={submitting || pristine || hasValidationErrors}
                                startIcon={<SaveIcon />}>{initialModel ? 'Save' : 'Create'}</Button>
                        </Grid>
                    </Grid>
                </form>
            )} />
    );
};
