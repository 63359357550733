import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useState } from 'react';
import { FormRender } from '../../models/common';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

interface OwnProps {
    title?: React.ReactNode;
    form: React.ReactElement<FormRender<any>>;
    open?: boolean;
    size?: 'min' | 'normal' | 'page';
}

export default (props: OwnProps) => {
    const [actionsRef, setSetActionsRef] = useState<HTMLDivElement | undefined>();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useClasses();

    return (
        <Dialog
            aria-labelledby='form-dialog-title'
            disableBackdropClick
            keepMounted={false}
            fullScreen={props.size === 'page' || mobile}
            classes={{
                paper: clsx(classes.paper, props.size ?? 'normal')
            }}
            open={!!props.open}>
            {props.title && (<DialogTitle id='form-dialog-title'>{props.title}</DialogTitle>)}
            <DialogContent>{React.cloneElement(props.form, { actionsRef })}</DialogContent>
            <DialogActions ref={setSetActionsRef} />
        </Dialog>
    );
};

const useClasses = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.up('sm')]: {
            '&.min': {

            },
            '&.normal': {
                minWidth: 500,
                minHeight: 400
            },
            '&.page': {

            }
        }
    }
}));
