import { ImageProcessing, AttachedFile } from './common';
import { getImageUrl } from '../common';

export interface KidMetrics {
    weight: number | null;
    height: number | null;
}
export interface KidModify {
    name: string;
    birthDate: string | null;
    birthMetric: KidMetrics | null;
    photoFileId: string | null;
}

export interface Kid extends KidModify {
    kidId: string;
    ownerUserId: string;
}

interface KidStatsDataPoint {
    date: string;
    metric: number;
}
export interface KidStats {
    weight: KidStatsDataPoint[];
    height: KidStatsDataPoint[];
}

export interface KidRelation {
    userId: string;
    type: 'Owner' | 'Viewer' | 'Contribute';
    name?: string;
    email?: string;
}

export const KID_IMAGE: ImageProcessing = {
    convert: 'JPG',
    resize: [{ width: 300 }, { width: 800 }]
};

export const getKidImageUrl = (kid: Kid, type = 0) =>
    kid.photoFileId
        ? getImageUrl(kid.photoFileId, KID_IMAGE.convert!, KID_IMAGE.resize?.[type])
        : undefined;

export interface KidMemoryModify {
    occurredOn: string;
    text: string | null;
    metric: KidMetrics | null;
    files: AttachedFile[];
}


export interface KidMemory extends KidMemoryModify {
    memoryId: string;
    kidId: string;
    addedOn: string;
    ownerUserId: string;
}


export const MEMORY_IMAGE: ImageProcessing = {
    convert: 'JPG',
    resize: [{ width: 300 }, { width: 900 }]
};

export const getKidMemoryImageUrl = (fileId: string, type = 0) =>
    getImageUrl(fileId, MEMORY_IMAGE.convert!, MEMORY_IMAGE.resize?.[type]);
