import create from 'zustand';
import createApi from 'zustand/vanilla';
import { ActiveNotification } from '../models/common';

export interface NotificationsStore extends Record<any, unknown> {
    notifications: ActiveNotification[];
    add: (text: string) => void;
    remove: (id: number) => void;
}

const notificationsStoreApi = createApi<NotificationsStore>((set) => ({
    notifications: [],
    add: (text: string) => set((state) => {
        const id = Math.round(Math.random() * 1e4);

        // schedule removal
        setTimeout(() => state.remove(id), 6000);

        return {
            notifications: [...state.notifications].concat([{
                text,
                id
            }])
        };
    }),
    remove: (id: number) => set((state) => ({
        notifications: state.notifications.filter(n => n.id !== id)
    }))
}));

const useNotificationsStore = create(notificationsStoreApi);
export { useNotificationsStore, notificationsStoreApi };
