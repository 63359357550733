import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RecipeSummary } from '../../models/cooking';
import RecipeSummaryCard, { RecipeSummaryEmptyCard } from './RecipeSummaryCard';
import { Grid, Menu, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';


export default ({ recipes }: { recipes?: RecipeSummary[] }) => {
    const classes = useStyles();
    const [menu, setMenu] = useState<null | [HTMLElement, string]>(null);
    const history = useHistory();

    return (
        <React.Fragment>
            <Grid container spacing={4} justify='space-evenly'>
                {
                    recipes
                        ? recipes.map((r) => (
                            <Grid item key={r.recipeId}>
                                <RecipeSummaryCard
                                    recipe={r}
                                    className={classes.item}
                                    onMenuClick={(e) => setMenu([e, r.recipeId])} />
                            </Grid>))
                        : [1, 2, 3, 4].map((id) => (
                            <Grid item key={id}>
                                <RecipeSummaryEmptyCard
                                    key={id}
                                    className={classes.item} />
                            </Grid>))}
            </Grid>
            <Menu
                id='recipe-settings-menu'
                anchorEl={menu?.[0]}
                open={Boolean(menu?.[0])}
                onClose={() => setMenu(null)}>
                <MenuItem onClick={() => history.push(`/recipes/${menu?.[1]}/edit`)}>
                    <ListItemIcon><EditIcon fontSize='small' /></ListItemIcon>
                    <Typography variant='inherit'>Edit Recipe</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            height: '100%'
        }
    }),
);
