import * as React from 'react';
import { Kid, KidStats } from '../../models/family';
import { Paper, makeStyles, IconButton, Grid, Hidden, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import KidAvatar from './KidAvatar';
import { friendlyFromNow } from '../../common/humanize';
import ChartitGraphLine from 'react-chartist';
import 'chartist/dist/chartist.css';
import { useState, useEffect, useMemo } from 'react';
import { useIsMounted } from '../../common/hooks';
import apiClient from '../../services/apiClient';
import { ILineChartOptions } from 'chartist';
import moment from 'moment';
import Qty from 'js-quantities';
import { useUserStore } from '../../stores/userStore';

export default ({ kid }: { kid: Kid }) => {
    const user = useUserStore((state) => state.user);
    const classes = useStyles();
    const [metrics, setMetrics] = useState<KidStats | null>(null);
    const mounted = useIsMounted();

    useEffect(() => {
        setMetrics(null);
        apiClient
            .callApi<KidStats>({ url: `/kids/${kid.kidId}/stats` })
            .then((r) => mounted.current && setMetrics(r));
    }, [kid, mounted]);

    const series = useMemo(() => metrics
        ? [{
            name: 'height',
            data: metrics.height.map((h) => ({
                x: new Date(h.metric),
                y: h.metric
            }))
        },
        {
            name: 'weight',
            data: metrics.weight.map((w) => ({
                x: new Date(w.metric),
                y: w.metric
            }))
        }]
        : [], [metrics]);

    return (
        <React.Fragment>
            <Hidden smDown>
                <Paper className={classes.paper}>
                    <div className={classes.avatar}>
                        <KidAvatar kid={kid} size='large' color='secondary' />
                    </div>
                    {user?.profile.sub === kid.ownerUserId && (
                            <IconButton
                                className={classes.edit}
                                title='Edit Details'
                                aria-label='edit kid details'
                                component={Link}
                                to={`/kids/${kid.kidId}/edit`}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                        )
                    }
                    {kid.birthDate && (
                        <Grid container className={classes.stats}>
                            {kid.name}'s birthday {friendlyFromNow(kid.birthDate)}
                        </Grid>
                    )}
                    {!!metrics?.weight.length && series && (
                            <React.Fragment>
                                <Typography variant='caption' component='h5' className={classes.chartTitle}>
                                        Weight {new Qty(metrics!.weight.slice(-1)[0].metric, 'gram').toString('kg')}
                                        &nbsp;last reported&nbsp;
                                        {moment(metrics?.weight.slice(-1)[0].date).fromNow()}
                                </Typography>
                                <ChartitGraphLine
                                    data={{ series: [series.find(f => f.name === 'weight')] }}
                                    type='Line'
                                    options={{
                                        fullWidth: true,
                                        series: {
                                            weight: {
                                                showPoint: !!series.find(
                                                    f => f.name === 'weight' && f.data.length < 5)
                                            }
                                        },
                                        axisY: {
                                            showLabel: false,
                                            offset: 0,
                                        }
                                    } as ILineChartOptions} />
                            </React.Fragment>
                        )
                    }
                    {
                        !!metrics?.height.length && series && (
                           <React.Fragment>
                                <Typography variant='caption' component='h5' className={classes.chartTitle}>
                                            Height {metrics!.height.slice(-1)[0].metric} cm last reported&nbsp;
                                            {moment(metrics?.height.slice(-1)[0].date).fromNow()}
                                </Typography>
                                <ChartitGraphLine
                                data={{ series: [series.find(f => f.name === 'height')] }}
                                type='Line'
                                options={{
                                    fullWidth: true,
                                    series: {
                                        height: {
                                            showPoint: !!series.find(
                                                f => f.name === 'height' && f.data.length < 5)
                                        }
                                    },
                                    axisY: {
                                        showLabel: false,
                                        offset: 0
                                    }
                                } as ILineChartOptions} />
                           </React.Fragment>
                        )
                    }
                </Paper>
            </Hidden>
            <Hidden smUp>
                <Grid container justify='center' className={classes.mobileRoot}>
                    <KidAvatar
                        kid={kid}
                        size='large'
                        color='secondary'
                        url={user?.profile.sub === kid.ownerUserId ? `/kids/${kid.kidId}/edit` : undefined} />
                    <div className={classes.mobileStats}>
                        {!!metrics?.weight.length && (
                            <Typography variant='caption' component='div' className={classes.chartTitle}>
                                {new Qty(metrics!.weight.slice(-1)[0].metric, 'gram').toString('kg')}
                            </Typography>
                        )}
                        {
                            !!metrics?.height.length && (
                                <Typography variant='caption' component='div' className={classes.chartTitle}>
                                    {metrics!.height.slice(-1)[0].metric} cm
                                </Typography>
                            )
                        }
                    </div>
                </Grid>
            </Hidden>
        </React.Fragment>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        position: 'relative',
        '&:before': {
            content: '""',
            display: 'table'
        }
    },
    avatar: {
        width: '50%',
        maxHeight: 130,
        margin: '-28px auto 0',
        display: 'flex',
        justifyContent: 'center'
    },
    edit: {
        position: 'absolute',
        right: 2,
        top: 2,
        padding: 6
    },
    stats: {
        padding: '12px 12px 6px',
    },
    chartTitle: {
        padding: '0 12px 0',
    },
    mobileRoot: {
        position: 'relative'
    },
    mobileStats: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}));
