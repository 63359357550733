import create from 'zustand';
import createApi from 'zustand/vanilla';
import { User } from 'oidc-client';

export interface UserStore extends Record<any, unknown> {
    user: User | null;
}

const userStoreApi = createApi<UserStore>(() => ({
    user: null
}));

const useUserStore = create(userStoreApi);

export { useUserStore, userStoreApi };
