import React, { useState } from 'react';
import { IconButton, MenuItem, Menu, Button, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Person from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import { getReturnUrl } from '../../common/location';
import { useUserStore } from '../../stores/userStore';
import { useNotificationsStore } from '../../stores/notificationsStore';
import authService from '../../services/authService';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import SwapVertIcon from '@material-ui/icons/SwapVert';

interface OwnProps {
    onClick?: () => void;
    className?: string;
}

export default ({ className, onClick }: OwnProps) => {
    const user = useUserStore((state) => state.user);
    const addNotification = useNotificationsStore((state) => state.add);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();

    const loginRedirect = () => {
        authService.signinRedirect({ state: { returnUrl: getReturnUrl() } })
            .catch(() => addNotification('There was a problem during login'));
    };

    const logoutRedirect = () => {
        authService.signoutRedirect()
            .catch(() => addNotification('There was a problem during logout'));
    };

    if (!user || user.expired) {
        return (
            <Button onClick={() => loginRedirect()}>Login</Button>
        );
    }

    return (
        <div className={clsx(className)}>
            <IconButton
                aria-label='menu profile'
                aria-owns={anchorEl ? 'menu-profile' : undefined}
                aria-haspopup='true'
                onClick={(e) => setAnchorEl(e.currentTarget)}
                title={`Hello ${user.profile.name}!`}>
                <AccountBox />
            </IconButton>
            <Menu
                id='menu-profile'
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
                onClick={onClick}
                classes={{
                    list: classes.list,
                    paper: classes.paper
                }}>
                <MenuItem onClick={() => setAnchorEl(null)} button component={Link} to='/profile'>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    {user.profile.name &&
                        (<ListItemText primary='Profile' secondary={user.profile.name} />)}
                </MenuItem>
                <MenuItem onClick={() => setAnchorEl(null)} button component={Link} to='/invites'>
                    <ListItemIcon><SwapVertIcon /></ListItemIcon>
                    <ListItemText primary='Invitations' />
                </MenuItem>
                <MenuItem onClick={() => logoutRedirect()}>
                    <ListItemIcon>
                        <PowerSettingsNew />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                </MenuItem>
            </Menu>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        width: 250
    },
    list: {
        '& .MuiListItemText-root p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    }
}));
