import * as React from 'react';
import { CssBaseline, createMuiTheme, useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useMemo, PropsWithChildren } from 'react';

const createTheme = (isDark: boolean) => createMuiTheme({
    palette: {
        primary: {
            main: '#00c853',
        },
        secondary: {
            main: '#9e9e9e',
        },
        type: isDark ? 'dark' : 'light'
    },
    typography: {
        fontFamily: 'Muli,Roboto,\'Helvetica\',Arial,sans-serif'
    }
});

export default ({ children }: PropsWithChildren<{}>) => {
    const isDarkScheme = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(() => createTheme(isDarkScheme), [isDarkScheme]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>);
};
