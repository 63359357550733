import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useKidsStore } from '../../stores/kidsStore';
import shallow from 'zustand/shallow';
import { useNotFound, useTitle } from '../../common/hooks';
import MemoriesTimeline from '../../components/kids/memories/MemoriesTimeline';
import KidCard from '../../components/kids/KidCard';

export default () => {
    const { kidId } = useParams<{ kidId?: string }>();
    const kidsStore = useKidsStore((s) => ({
        items: s.items,
        ensure: s.ensure,
        ensured: s.ensured,
        fetching: s.fetching
    }), shallow);
    const classes = useStyles();
    useEffect(() => void kidsStore.ensure(), [kidsStore]);

    const kid = useMemo(() => kidsStore.items.find((k) => k.kidId === kidId), [kidsStore, kidId]);
    useNotFound(() => !kid && kidsStore.ensured && !kidsStore.fetching, [kid, kidsStore]);
    useTitle(kid ? `${kid.name}'s memories` : null, [kid]);

    return kid
        ? (
            <Grid item container className={classes.root}>
                <Grid item xs={12} md={9} className={classes.timeline}>
                    <MemoriesTimeline kid={kid} />
                </Grid>
                <Grid item xs={12} md={3} className={classes.profile}>
                    <KidCard kid={kid} />
                </Grid>
            </Grid>
        )
        : null;
};

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            marginTop: -24,
        }
    },
    profile: {
        marginTop: 6,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    timeline: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 6
        }
    }
}));
