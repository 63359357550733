import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import { useCommonStore } from '../../stores/commonStore';

interface OwnProps {
    drawerOpened: boolean;
    toggleClick: () => void;
}

export default ({ drawerOpened, toggleClick }: OwnProps) => {
    const classes = useStyles();
    const title = useCommonStore((state) => state.title);

    return (
        <AppBar position='fixed'
            className={clsx(classes.appBar, drawerOpened && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge='start'
                    aria-label='Open drawer'
                    onClick={toggleClick}
                    className={clsx(classes.menuButton, drawerOpened && classes.menuButtonHidden)}>
                    <MenuIcon />
                </IconButton>
                <Typography
                    component='h1'
                    variant='h6'
                    color='inherit'
                    noWrap
                    className={classes.title}>
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>);
};

const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        paddingLeft: 16
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#33691e',
        color: '#fff'
    },
    appBarShift: {
        width: '100%'
    },
    menuButton: {
        marginRight: 8,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            display: 'block'
        }
    }
}));
