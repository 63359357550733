import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIsMounted } from '../../common/hooks';
import { useHistory } from 'react-router-dom';
import authService from '../../services/authService';
import { Link } from '@material-ui/core';

export default () => {
    const mounted = useIsMounted();
    const history = useHistory();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!mounted.current) { return; }
        authService.signinRedirectCallback()
            .then((user) => history.push((user.state?.returnUrl) || '/'))
            .catch((e) => {
                console.error('Failed in login callback', e);
                setError(true);
            });
    });
    return (<div>{
        error
            ? (<div>Something went wrong and we couldn't complete signing-in.
                You can start over from the <Link href='/'>home page</Link>.</div>)
            : 'Just a few more moments to finalize secure session...'
    }</div>);
};
