export interface FileView {
    fileId: string;
    fileName: string | null;
    fileType: FileType | null;
    fileSize: number;
    createdOn: string;
    mimeType: string | null;
    readOnly: boolean;
}

export type FileType = 'Image' | 'Other';
export type ConvertTypes = null | 'PNG' | 'JPG';
export interface ImageSize {
    width?: number | null;
    height?: number | null;
}
export interface ImageProcessing {
    convert?: ConvertTypes;
    resize?: null | ImageSize[];
}

export interface ActiveNotification {
    text: string;
    id: number;
}

export interface Paged<T> {
    total: number;
    currentPage: number;
    pageSize: number;
    items: T[];
}

export const EmptyPaged: Paged<any> = {
    total: 0,
    currentPage: 0,
    pageSize: 25,
    items: []
};

export interface FormContract<TSubmit, TResult> {
    apiCall: (model: TSubmit) => Promise<TResult>;
    error: () => void;
    success?: (model: TResult) => void;
}
export interface FormRender<TSubmit, TResult = TSubmit>
    extends FormContract<TSubmit, TResult> {
    initialModel?: TSubmit;
    actionsRef?: HTMLElement;
}
export interface FormSubmit<TSubmit, TResult = TSubmit>
    extends FormContract<TSubmit, TResult> {
    submitModel: TSubmit;
}

export interface AttachedFile {
    name: string | null;
    fileId: string;
}

export interface UserSettingsModify {
    name?: string;
}

export interface UserSettingsView extends UserSettingsModify {
    userId: string;
    email?: string;
}

export interface InviteView {
    inviteId: string;
    fromUserId: string;
    fromUser?: UserSettingsView;
    addedOn: string;
    status: 'Waiting' | 'Canceled' | 'Accepted' | 'Rejected';
    statusChangedOn: string;
    entityType: 'App' | 'Kid';
    entityId?: string;
    recipientEmail: string;
    recipientUser?: UserSettingsView;
}
