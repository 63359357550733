import * as React from 'react';
import { Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MainLayout from './layouts/main';
import MinLayout from './layouts/minimal';
import CallbackLogin from './pages/auth/CallbackLogin';
import CallbackLogout from './pages/auth/CallbackLogout';
import Page from './pages/Page';
import ProfilePage from './pages/profile/ProfilePage';
import Notifications from './components/Notifications';
import { authRoutes } from './services/authService';
import NotFoundPage from './pages/NotFoundPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import Theme from './layouts/Theme';
import NewRecipePage from './pages/recipes/New';
import ViewRecipePage from './pages/recipes/View';
import ListRecipesPage from './pages/recipes/List';
import EditRecipePage from './pages/recipes/Edit';
import NewKidPage from './pages/kids/NewKid';
import EditKidPage from './pages/kids/EditKid';
import ViewMemoriesPage from './pages/kids/Memories';
import AddMemoryPage from './pages/kids/AddMemory';
import EditMemoryPage from './pages/kids/EditMemory';
import InvitesPage from './pages/InvitesPage';

export default () => (
    <Theme>
        <ErrorBoundary>
            <Router>
                <Switch>
                    <Page exact layout={MainLayout} path={'/not-found'}
                        component={NotFoundPage} title='Not Found' />
                    <Page exact layout={MainLayout} path={['/', '/home']} component={HomePage} title='Main Page' />
                    <Page exact layout={MinLayout} path={authRoutes.loginCallback}
                        component={CallbackLogin} title='Logging in' />
                    <Page exact layout={MinLayout} path={authRoutes.logoutCallback}
                        component={CallbackLogout} title='Logging out' />
                    <Page exact layout={MainLayout} path='/profile' component={ProfilePage} title='Your Profile' />

                    <Page exact layout={MainLayout} path={['/recipes', '/:cookbookId/recipes']}
                        component={ListRecipesPage} title='Recipes' />
                    <Page exact layout={MainLayout} path={['/recipes/new', '/:cookbookId/recipes/new']}
                        component={NewRecipePage} title='Add New Recipe' />
                    <Page exact layout={MainLayout} path='/recipes/:recipeId'
                        component={ViewRecipePage} title='Recipe' />
                    <Page exact layout={MainLayout} path='/recipes/:recipeId/edit'
                        component={EditRecipePage} title='Edit Recipe' />

                    <Page exact layout={MainLayout} path={'/kids/new'}
                        component={NewKidPage} title='Add New Kid' />
                    <Page exact layout={MainLayout} path='/kids/:kidId/edit'
                        component={EditKidPage} />
                    <Page exact layout={MainLayout} path={['/kids/:kidId/memories', '/kids/:kidId']}
                        component={ViewMemoriesPage} />
                    <Page exact layout={MainLayout} path='/kids/:kidId/memories/new'
                        component={AddMemoryPage} title='Add New Memory' />
                    <Page exact layout={MainLayout} path='/memories/:memoryId/edit'
                        component={EditMemoryPage} title='Edit Memory' />
                    <Page exact layout={MainLayout} path='/invites'
                        component={InvitesPage} title='Your invitations' />

                    <Redirect from='*' to='/not-found' />
                </Switch>
            </Router>
        </ErrorBoundary>
        <Notifications />
    </Theme>
);
