import * as React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h2>Not Found</h2>
            <p>The thing you were looking for was not found</p>
        </div>
    );
};

export default NotFoundPage;
