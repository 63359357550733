import * as React from 'react';
import KidForm from '../../components/kids/KidForm';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { useHistory } from 'react-router-dom';
import { useIsMounted } from '../../common/hooks';
import { useKidsStore } from '../../stores/kidsStore';
import { Grid } from '@material-ui/core';

export default () => {
    const addNotification = useNotificationsStore((state) => state.add);
    const history = useHistory();
    const mounted = useIsMounted();
    const addKid = useKidsStore((s) => s.add);

    return (
        <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
                <KidForm
                    apiCall={addKid}
                    error={() => addNotification('There was a problem adding a kid. Please try again later.')}
                    success={(m) => mounted.current && history.push(`/kids/${m.kidId}`)}
                />
            </Grid>
        </Grid>
    );
};
