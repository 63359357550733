import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useCommonStore } from '../stores/commonStore';

const useStyles = makeStyles({
    root: {
        height: 4,
        position: 'fixed',
        zIndex: 10000,
        width: '100%'
    }
});

export default () => {
    const classes = useStyles();
    const tasks = useCommonStore((state) => state.tasks);

    return (
        <div className={classes.root} >
            {tasks > 0 && <LinearProgress />}
        </div>
    );
};
