import * as React from 'react';
import { TextField } from '@material-ui/core';
import { useUserStore } from '../../stores/userStore';

export default () => {
    const user = useUserStore((state) => state.user);
    return (
        <form noValidate autoComplete='off'>
            <TextField
                label='Email'
                defaultValue={user?.profile.name}
                disabled
            />
        </form>
    );
};
