import * as React from 'react';
import { Typography, Fab, Grid, makeStyles, IconButton } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { Link } from 'react-router-dom';
import { useKidsStore } from '../stores/kidsStore';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import shallow from 'zustand/shallow';
import FormDialog from '../components/common/FormDialog';
import { useState } from 'react';
import { Kid, KidMemory } from '../models/family';
import MemoryForm, { FormMode } from '../components/kids/memories/MemoryForm';
import apiClient from '../services/apiClient';
import { useNotificationsStore } from '../stores/notificationsStore';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import { useCookbooksStore } from '../stores/cookbooksStore';
import PostAddIcon from '@material-ui/icons/PostAdd';

const HomePage = () => {
    const classes = useStyles();
    const kids = useKidsStore((s) => ({ items: s.items, ensured: s.ensured }), shallow);
    const cookbooks = useCookbooksStore((s) => ({ items: s.items, ensured: s.ensured }), shallow);
    const [selectedKid, setSelectedKid] = useState<[Kid, FormMode?] | null>(null);
    const addNotification = useNotificationsStore((state) => state.add);

    return (
        <div className={classes.root}>
            <Typography component='h1' variant='h3'>Hi!</Typography>
            <Grid container spacing={4}>
                {
                    kids.items.map((k) => (
                        <Grid item key={k.kidId}>
                            <Grid container direction='column' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Typography variant='caption'>{k.name}</Typography>
                                </Grid>
                                <Grid item container direction='column' alignItems='center'>
                                    <Grid item>
                                        <Fab
                                            color={selectedKid?.[0] === k ? 'default' : 'primary'}
                                            aria-label={`Add to ${k.name}'s timeline`}
                                            size='large'
                                            onClick={() => setSelectedKid([k])}>
                                            <PlaylistAddIcon />
                                        </Fab>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            className={classes.smallIconButton}
                                            title='Report weight or height'
                                            aria-label='report weight or height'
                                            onClick={() => setSelectedKid([k, FormMode.Metric])}>
                                            <AssessmentIcon />
                                        </IconButton>
                                        <IconButton
                                            className={classes.smallIconButton}
                                            title='Add image/s to a new memory'
                                            aria-label='upload images'
                                            onClick={() =>
                                                setSelectedKid([k, FormMode.Text | FormMode.Image])}>
                                            <ImageIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid item>
                    <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                            <Typography variant='button'>Add Kid...</Typography>
                        </Grid>
                        <Grid item alignItems='center' container direction='column'>
                            <Grid item>
                                <Fab color={kids.ensured && kids.items.length ? 'secondary' : 'primary'}
                                    aria-label='Add Kid'
                                    size='large'
                                    component={Link}
                                    to='/kids/new'>
                                    <FaceIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                selectedKid && (
                    <FormDialog
                        open
                        title={`Add to ${selectedKid[0].name}'s timeline`}
                        form={(
                            <MemoryForm
                                mode={selectedKid[1]}
                                apiCall={(m) => apiClient
                                    .callApi<KidMemory>({
                                        url: `/kids/${selectedKid[0].kidId}/memories`,
                                        method: 'POST',
                                        requestData: m
                                    })}
                                error={() => addNotification('There was a problem adding a memory. Please try again later.')}
                                success={() => {
                                    setSelectedKid(null);
                                    addNotification(`Added to ${selectedKid[0].name}'s timeline`);
                                }}
                                onCancel={() => setSelectedKid(null)} />
                        )}
                    />)
            }
            <Grid container spacing={4}>
                {
                    cookbooks.ensured && cookbooks.items.length
                        ? cookbooks.items.map((c) => (
                            <Grid item>
                                <Grid container direction='column' alignItems='center' spacing={1}>
                                    <Grid item>
                                        <Typography variant='button'>{c.name}</Typography>
                                    </Grid>
                                    <Grid item alignItems='center' container direction='column'>
                                        <Grid item>
                                            <Fab color='primary'
                                                aria-label='Add Recipe'
                                                size='large'
                                                component={Link}
                                                to={`/recipes/new?cookbookId=${c.cookbookId}`}>
                                                <PostAddIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                        : (
                            <Grid item>
                                <Grid container direction='column' alignItems='center' spacing={1}>
                                    <Grid item>
                                        <Typography variant='button'>New Recipe...</Typography>
                                    </Grid>
                                    <Grid item alignItems='center' container direction='column'>
                                        <Grid item>
                                            <Fab color='primary'
                                                aria-label='Add Recipe'
                                                size='large'
                                                component={Link}
                                                to='/recipes/new'>
                                                <PostAddIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                }

            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        '&>div': {
            marginTop: 40
        }
    },
    smallIconButton: {
        padding: 10,
    }
}));

export default HomePage;
