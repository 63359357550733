import { cdnHost, appHost } from './config';
import { ImageSize } from '../models/common';

/**
  * Convert a flat structure of params into nested. E.g. this:
*
* ```js
* {
*  "birthMetrics.weight" : ["must be between 1 and 2"]
* }
* ```
* into:
*
* ```js
* {
*  "birthMetrics": { "weight":  ["must be between 1 and 2"] }
* }
* ```
 * @param flat Flat object
 * @param camelCase Whether to camel case field names
 */
export function flatToNested(flat: Record<string, any>, camelCase = true): Record<string, any> {
    const nested: Record<string, any> = {};

    Object
        .keys(flat)
        .forEach((flatKey) => { // e.g. `cardLimits.expiration`
            let node = nested; // single node at some level
            flatKey
                .split('.')
                .forEach((nestedKey, i, a) => {
                    nestedKey = camelCase
                        ? nestedKey[0].toLowerCase() + (nestedKey.length > 1 ? nestedKey.substr(1) : '')
                        : nestedKey;
                    if (i === a.length - 1) {
                        // final nesting or the `flatKey` didn't contain `.` =>
                        // just put a value of the original key
                        node[nestedKey] = flat[flatKey];
                    } else if (!node[nestedKey]) {
                        // we in the beginning/middle of nesting =>
                        // create empty sub-object, so in next iteration
                        // of sub-key we either place value on it, or continue
                        // to create sub-objects
                        node[nestedKey] = {};
                    }

                    // that is fine to assign `null` to `node`,
                    // because it means that we in most nested node
                    node = node[nestedKey] as any;
                });
        });

    return nested;
}

export const later = (func: () => void) => setTimeout(func, 0);

export const fallBackImage = (normalExists: boolean, normalImage: string | undefined, fallbackIdentifier: number) => {
    if (normalExists) {
        return `${cdnHost}/${normalImage}`;
    }
    return `${appHost}/media/no-image/${fallbackIdentifier % 8 + 1}.jpg`;
};

export const getImageUrl = (
    fileId: string,
    type: 'PNG' | 'JPG',
    processed?: ImageSize) =>
    `${cdnHost}/${fileId}${processed
        ? (`/${processed.width ?? 'auto'}_${processed.height ?? 'auto'}`)
        : ''}.${type.toLowerCase()}`;


/**
 * Searches sorted by specified key array.
 * @param arr The sorted array.
 * @param key The object key by which the array is sorted.
 * @param value The value to look for
 */
export const binarySearch = <
    T extends Record<K, V> & Record<string, any>,
    K extends keyof T,
    V = string | number>(arr: T[], key: K, value: V) => {
    let start = 0;
    let end = arr.length - 1;

    while (start <= end) {
        let mid = Math.floor((start + end) / 2);

        if (arr[mid][key] === value) {
            return mid;
        }

        if (value < arr[mid][key]) {
            end = mid - 1;
        } else {
            start = mid + 1;
        }
    }
    return -1;
};

export const getFilePreview = (file: File) => new Promise<string | null>((p) => {
    if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (() => p(reader.result as string));
        reader.readAsDataURL(file);
    } else {
        p(null);
    }
});
