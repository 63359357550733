import * as React from 'react';
import KidForm from '../../components/kids/KidForm';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { useHistory, useParams } from 'react-router-dom';
import { useIsMounted, useTitle } from '../../common/hooks';
import { useKidsStore } from '../../stores/kidsStore';
import { useEffect, useState } from 'react';
import { Kid } from '../../models/family';
import apiClient from '../../services/apiClient';
import { Grid } from '@material-ui/core';
import KidPermissions from '../../components/kids/KidPermissions';

export default () => {
    const { kidId, returnUrl } = useParams<{ kidId?: string; returnUrl?: string }>();
    const [kid, setKid] = useState<Kid | null>(null);
    const addNotification = useNotificationsStore((state) => state.add);
    const history = useHistory();
    const mounted = useIsMounted();
    const updateKid = useKidsStore((s) => s.update);

    useEffect(
        () => void (
            kidId && apiClient
                .callApi<Kid>({ url: `/kids/${kidId}` })
                .then((r) => setKid(r))
                .catch((e) => {
                    if (e.status === 404) {
                        mounted.current && history.push(`/not-found`);
                    } else {
                        addNotification('Failed to get kid details. Please try again later.');
                    }
                })),
        [kidId, addNotification, history, mounted]);

    useTitle(kid ? `Edit ${kid?.name}` : null, [kid]);

    return (
        <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
                {
                    kid && <KidForm
                        initialModel={kid}
                        apiCall={(m) => updateKid(kid.kidId, m)}
                        error={() => addNotification('There was a problem modifying this kid. Please try again later.')}
                        success={(m) => mounted.current && history.push(returnUrl ?? `/kids/${m.kidId}`)}
                    />
                }
            </Grid>
            <Grid item md={6} xs={12}>
                {
                    kid && <KidPermissions
                        model={kid} />
                }
            </Grid>
        </Grid>

    );
};
