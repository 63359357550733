import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    IconButton, Dialog, DialogTitle, DialogContent, Menu,
    MenuItem, ListItemIcon, ListItemText, useMediaQuery, AppBar,
    Toolbar, Typography, ButtonBase, Fab
} from '@material-ui/core';
import { getFilePreview } from '../../common';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import GridOnIcon from '@material-ui/icons/GridOn';
import clsx from 'clsx';
import ConfirmDialog from './ConfirmDialog';
import ReactDOM from 'react-dom';
import PromptDialog from './PromptDialog';

interface MediaFile {
    fileId: string;
    local?: File;
    name?: string | null;
}
interface MediaFileResolved extends MediaFile {
    url: string;
}

interface FilesMediaViewProps {
    files: MediaFile[];
    zoomFile?: MediaFile;
    fileUrlFactory: (fileId: string, type?: number) => string;
    onNameChanged?: (fileId: string, newName: string | null) => void;
    onDeleteConfirmed?: (fileId: string) => void;
    className?: string;
    toolbarRef?: HTMLElement;
    title?: string;
    titleRef?: HTMLElement;
}

interface ZoomViewProps {
    file: MediaFileResolved;
    onEditClick?: (file: MediaFileResolved, el: HTMLElement) => void;
    onBackToGridClick?: (fileId: string) => void;
    onPrevNavigateClick?: (fileId: string) => void;
    onNextNavigateClick?: (fileId: string) => void;
    toolbarRef?: HTMLElement;
}
const ZoomView = ({ file, ...props }: ZoomViewProps) => {
    const classes = useZoomView();
    return (
        <div
            className={classes.root}
            style={{ backgroundImage: `url(${file.url})` }}>
            <div className={classes.navContainer}>
                {
                    props.onPrevNavigateClick && (
                        <Fab color='secondary'
                            aria-label='previous'
                            size='medium'
                            onClick={() => props.onPrevNavigateClick?.(file.fileId)}>
                            <ChevronLeftIcon />
                        </Fab>
                    )
                }
            </div>
            <div className={clsx(classes.navContainer, 'last')}>
                {
                    props.onNextNavigateClick && (
                        <Fab color='secondary'
                            aria-label='next'
                            size='medium'
                            onClick={() => props.onNextNavigateClick?.(file.fileId)}>
                            <ChevronRightIcon />
                        </Fab>
                    )
                }
            </div>
            {
                props.toolbarRef
                    ? ReactDOM.createPortal((
                        <>
                            {
                                props.onBackToGridClick && (
                                    <IconButton
                                        aria-label='back to grid'
                                        color='inherit'
                                        onClick={() => props.onBackToGridClick?.(file.fileId)}>
                                        <GridOnIcon />
                                    </IconButton>
                                )
                            }
                            {
                                props.onEditClick && (
                                    <IconButton
                                        aria-label='image settings'
                                        color='inherit'
                                        onClick={(e) => props.onEditClick?.(file, e.currentTarget)}>
                                        <MoreVertIcon />
                                    </IconButton>)
                            }
                        </>
                    ), props.toolbarRef)
                    : (
                        <div className={classes.backContainer}>
                            {
                                props.onBackToGridClick && (
                                    <IconButton
                                        aria-label='back to grid'
                                        size='medium'
                                        onClick={() => props.onBackToGridClick?.(file.fileId)}>
                                        <GridOnIcon />
                                    </IconButton>
                                )
                            }
                            {
                                props.onEditClick && (
                                    <IconButton
                                        aria-label='image settings'
                                        size='medium'
                                        onClick={(e) => props.onEditClick?.(file, e.currentTarget)}>
                                        <MoreVertIcon />
                                    </IconButton>)
                            }
                        </div>
                    )
            }
        </div>
    );
};

const useZoomView = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.background.default
        }
    },
    navContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        '&.last': {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.up('sm')]: {
            '& button': {
                opacity: 0,
                transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                })
            },
            '&:hover button': {
                opacity: 1,
                transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })
            },
        }
    },
    backContainer: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2)
    }
}));

interface ImageTileProps {
    file: MediaFileResolved;
    onEditClick?: (file: MediaFileResolved, el: HTMLElement) => void;
    onZoomClick?: (file: MediaFileResolved) => void;
}
const ImageTile = ({ file, onEditClick, onZoomClick }: ImageTileProps) => {
    const [imageState, setImageState] = useState<null | 'land' | 'port'>(null);

    useEffect(() => {
        if (imageState) { return; }

        let img: HTMLImageElement | null = new Image();
        img.addEventListener(
            'load',
            (e: any) => {
                const el = e.currentTarget;
                el && setImageState(el.naturalWidth > el.naturalHeight ? 'land' : 'port');
                img = null;
            });
        img.src = file.url;

    }, [file.url, imageState]);

    return (
        <li
            className={clsx(imageState === 'land' && 'at-landscape', imageState === 'port' && 'at-portrait')}
            style={{ backgroundImage: imageState ? `url(${file.url})` : undefined }}>
            {
                onZoomClick && (
                    <ButtonBase
                        className='at-image-zoom'
                        onClick={() => onZoomClick(file)}
                        aria-label='zoom image' />
                )
            }
            {
                (file.name || onEditClick) && (
                    <div className='at-tile-bar'>
                        <div>{file.name}</div>
                        {imageState && onEditClick && (<div>
                            <IconButton
                                aria-label='settings'
                                className='at-media-icon'
                                onClick={(e) => onEditClick(file, e.currentTarget)}>
                                <MoreVertIcon fontSize='small' />
                            </IconButton>
                        </div>)}
                    </div>
                )
            }
        </li>
    );
};


interface GridViewProps {
    files: MediaFileResolved[];
    onEditClick?: (file: MediaFileResolved, el: HTMLElement) => void;
    onZoomRequested?: (file: MediaFileResolved) => void;
}
const GridView = (props: GridViewProps) => {
    const classes = useGridView();
    return (
        <ul className={classes.list}>
            {props.files.map((file) => (
                <ImageTile
                    key={file.fileId}
                    file={file}
                    onEditClick={props.onEditClick}
                    onZoomClick={props.onZoomRequested} />
            ))}
        </ul>
    );
};
const useGridView = makeStyles((theme) => ({
    list: {
        padding: 2,
        width: '100%',
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        margin: 0,
        '& li': {
            boxSizing: 'border-box',
            height: 180,
            flexBasis: 180,
            margin: 2,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            position: 'relative',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '50%',
            },

            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',

            '& button.at-image-zoom': {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 50,
                width: '100%',
                zIndex: 1,
                borderRadius: 0
            },

            '&.at-portrait': {
                flex: '1 0 130px',
            },
            '&.at-landscape': {
                flex: '2 0 300px',
            },
            '& .at-tile-bar': {
                display: 'flex',
                zIndex: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 50,
                background: 'rgba(0, 0, 0, 0.5)',
                '&>div:first-child': {
                    color: theme.typography.body2.color,
                    marginLeft: 16,
                    flexGrow: 1,
                    overflow: 'hidden',
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                '&>div:last-child': {

                },
                '& button': {
                    color: 'rgba(255, 255, 255, 0.54)',
                }

            }
        }
    },
}));

export const FilesMediaView = ({ files, fileUrlFactory, ...props }: FilesMediaViewProps) => {
    const classes = useFilesMediaView();
    const [filesResolved, setFilesResolved] = useState<MediaFileResolved[]>([]);
    const [zoomFile, setZoomFile] = useState(props.zoomFile);
    const [zoomFileResolved, setZoomFileResolved] = useState<MediaFileResolved | null>(null);
    const [anchorForSettings, setAnchorForSettings] = useState<null | [HTMLElement, MediaFileResolved]>(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [promptName, setPromptName] = useState(false);

    useEffect(() => {
        Promise
            .all(
                files.map((f) => f.local
                    ? getFilePreview(f.local).then((url) => ({ ...f, url: url ?? '/not-found.jpg' }))
                    : Promise.resolve({ ...f, url: fileUrlFactory(f.fileId, 0) })))
            .then((r) => setFilesResolved(r));
    }, [files, fileUrlFactory]);

    useEffect(() => setZoomFile(props.zoomFile), [props.zoomFile]);
    useEffect(() => {
        if (zoomFile) {
            if (zoomFile.local) {
                getFilePreview(zoomFile.local).then((url) => setZoomFileResolved({ ...zoomFile, url: url ?? '/not-found.jpg' }));
            } else {
                setZoomFileResolved({ ...zoomFile, url: fileUrlFactory(zoomFile.fileId, 1) });
            }
        }
        else {
            setZoomFileResolved(null);
        }
    }, [zoomFile, fileUrlFactory]);

    const title = useMemo(() => {
        if (zoomFile?.name) {
            return zoomFile.name;
        }
        if (filesResolved.length > 1 && zoomFile) {
            return `File ${filesResolved
                .findIndex((f) => f.fileId === zoomFile.fileId) + 1} of ${filesResolved.length}`;
        }
        return props.title;
    }, [props.title, filesResolved, zoomFile]);

    return (
        <div className={classes.root}>
            {props.titleRef && ReactDOM.createPortal(title, props.titleRef)}
            {
                zoomFileResolved
                    ? (<ZoomView
                        file={zoomFileResolved}
                        onBackToGridClick={filesResolved.length > 1
                            ? () => setZoomFile(undefined)
                            : undefined}
                        onEditClick={props.onNameChanged || props.onDeleteConfirmed
                            ? (f, e) => setAnchorForSettings([e, f])
                            : undefined}
                        onPrevNavigateClick={
                            filesResolved.length && filesResolved.findIndex(
                                (f) => f.fileId === zoomFileResolved.fileId) > 0
                                ? () => setZoomFile(filesResolved[filesResolved.findIndex(
                                    (f) => f.fileId === zoomFileResolved.fileId) - 1])
                                : undefined
                        }
                        onNextNavigateClick={
                            filesResolved.length && filesResolved.findIndex(
                                (f) => f.fileId === zoomFileResolved.fileId) < filesResolved.length - 1
                                ? () => setZoomFile(filesResolved[filesResolved.findIndex(
                                    (f) => f.fileId === zoomFileResolved.fileId) + 1])
                                : undefined
                        }
                        toolbarRef={props.toolbarRef}
                    />)
                    : (<GridView
                        files={filesResolved}
                        onEditClick={props.onNameChanged || props.onDeleteConfirmed
                            ? (f, e) => setAnchorForSettings([e, f])
                            : undefined}
                        onZoomRequested={setZoomFile} />)
            }

            <Menu
                id='image-settings'
                anchorEl={anchorForSettings?.[0]}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                open={anchorForSettings !== null}
                onClose={() => setAnchorForSettings(null)}>
                <MenuItem component='a'
                    onClick={() => setTimeout(() => setAnchorForSettings(null))}
                    href={fileUrlFactory(anchorForSettings?.[1].fileId!, -1)} target='_blank'>
                    <ListItemIcon>
                        <GetAppIcon />
                    </ListItemIcon>
                    <ListItemText primary='Download' />
                </MenuItem>
                {
                    props.onNameChanged && (
                        <MenuItem
                            onClick={() => setPromptName(true)}
                            button>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary='Edit Name' />
                        </MenuItem>
                    )
                }
                {
                    props.onDeleteConfirmed && (
                        <MenuItem onClick={() => setConfirmDelete(true)}>
                            <ListItemIcon>
                                <DeleteForeverIcon />
                            </ListItemIcon>
                            <ListItemText primary='Delete' />
                        </MenuItem>
                    )
                }
            </Menu>
            <ConfirmDialog
                open={confirmDelete}
                content={`Are sure you want to delete this image?`}
                onCancel={() => {
                    setConfirmDelete(false);
                    setAnchorForSettings(null);
                }}
                onOK={() => {
                    setConfirmDelete(false);
                    setAnchorForSettings(null);
                    props.onDeleteConfirmed?.(anchorForSettings?.[1].fileId!);
                }} />
            <PromptDialog
                open={promptName}
                title={`Image Name`}
                initialValue={anchorForSettings?.[1].name}
                onCancel={() => {
                    setPromptName(false);
                    setAnchorForSettings(null);
                }}
                onOK={(value) => {
                    setPromptName(false);
                    setAnchorForSettings(null);
                    props.onNameChanged?.(anchorForSettings?.[1].fileId!, value);
                }} />
        </div>
    );
};

const useFilesMediaView = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.breakpoints.values.md,
            minHeight: 500
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch'
    },
    gridView: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    }
}));

interface FilesMediaViewDialog extends FilesMediaViewProps {
    open?: boolean;
    onClose?: () => void;
    title?: string;
}
export const FilesMediaViewDialog = ({ open, onClose, ...props }: FilesMediaViewDialog) => {
    const classes = useFilesMediaViewDialog();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);
    const [titleRef, setTitleRef] = useState<HTMLElement | null>(null);

    return (
        <Dialog
            maxWidth='md'
            fullScreen={mobile}
            aria-labelledby='media-files-dialog-title'
            disableBackdropClick
            keepMounted={false}
            onClose={onClose}
            classes={{ container: classes.root }}
            open={!!open}>
            {
                mobile
                    ? (
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
                                    <ArrowBack />
                                </IconButton>
                                <Typography id='media-files-dialog-title'
                                    variant='h6' className={classes.title} ref={setTitleRef} />
                                <div className={classes.toolbarPortal} ref={setToolbarRef} />
                            </Toolbar>
                        </AppBar>
                    )
                    : (
                        <DialogTitle id='media-files-dialog-title' className={classes.dialogTitle}>
                            <Toolbar>
                                <Typography id='media-files-dialog-title'
                                    variant='h6' className={classes.title} ref={setTitleRef} />
                                <div ref={setToolbarRef} />
                                <IconButton edge='end' color='inherit' onClick={onClose} aria-label='close'>
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </DialogTitle>
                    )
            }
            <DialogContent>
                <FilesMediaView
                    {...props}
                    toolbarRef={toolbarRef ?? undefined}
                    titleRef={titleRef ?? undefined} />
            </DialogContent>
        </Dialog>
    );
};

const useFilesMediaViewDialog = makeStyles((theme) => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyItems: 'stretch'
            },
            [theme.breakpoints.up('sm')]: {
                overflowX: 'hidden'
            }
        }
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    dialogTitle: {
        padding: 0
    },
    toolbarPortal: {
        marginRight: -15
    }
}));
