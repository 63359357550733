import { flatToNested } from '.';
import { FormSubmit } from '../models/common';

/**
 * Defines how the server (status code 400) validation looks like.
 */
export interface ValidationErrors<TModel = any> {
    errors: { [key in keyof Partial<TModel>]: string[] };
    type: string;
    title: string;
    status: number;
    traceId: string;
}

/**
 * Determines whether the response object is a validation error type
 * @param object Any response from API
 */
export const isValidationErrors = <T = any>(object: any): object is ValidationErrors<T> => {
    const casted = object as ValidationErrors<T>;
    return casted.errors !== undefined
        && casted.type === 'https://tools.ietf.org/html/rfc7231#section-6.5.1';
};

export const submit = async <TSubmit, TResult = TSubmit>(
    { submitModel, apiCall, error, success }: FormSubmit<TSubmit, TResult>) => {
    try {
        const resultModel = await apiCall(submitModel);
        success?.(resultModel);
    }
    catch (err) {
        if (isValidationErrors(err)) {
            return flatToNested(err.errors);
        }
        error();
    }
};

type Validator = (value: any) => string | undefined;

/**
 * Merges multiple validators
 * @param validators list of validators
 */
export const compose = (...validators: Array<Validator>) => (value: any) =>
    validators.reduce((error: string | undefined, validator) => error || validator(value), undefined);

export const required = (message = 'Required'): Validator =>
    (value: any) => (value ? undefined : message);

export const minLength = (min: number, message = `Length should be equal or greater than ${min}`): Validator =>
    (value: any) => !value || (value + '').length < min ? message : undefined;

export const maxLength = (max: number, message = `Length should be less or equal to ${max}`): Validator =>
    (value: any) => value && (value + '').length > max ? message : undefined;

export const numberOnly = (message = 'Must be a number'): Validator =>
    (value: any) => (typeof value === 'string' && (value.match(/\./g) ?? []).length > 1)
        || (value && isNaN(value))
        ? message : undefined;

export const minNumber = (min: number, message = `Number should be equal or greater than ${min}`): Validator =>
    (value: any) => !isNaN(value) && parseFloat(value) < min ? message : undefined;

export const maxNumber = (max: number, message = `Number should be less or equal to ${max}`): Validator =>
    (value: any) => !isNaN(value) && parseFloat(value) > max ? message : undefined;

export const email = (message = 'Email address is not valid'): Validator =>
    (value: any) => ((/^[^\s@]+@[^\s@]+$/).test(value) ? undefined : message);
