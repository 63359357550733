import * as React from 'react';
import { FormControl, InputLabel, FormHelperText, IconButton, makeStyles, Avatar, Chip, Tooltip, Paper } from '@material-ui/core';
import { FileView, FileType, ConvertTypes, ImageProcessing } from '../../models/common';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import { useState, useEffect, useRef, useCallback } from 'react';
import apiClient, { CancellationToken } from '../../services/apiClient';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { getImageUrl, getFilePreview } from '../../common';

const fileItemCommonProps: Partial<typeof Chip> = {
    variant: 'outlined',
    size: 'small',
};
const SMALL_SIZE = { width: 150 };
const BIG_SIZE = { width: 800 };

const getImageProcessingQuery = (params?: ImageProcessing) => {
    // whatever requested + sizes that the picker needs
    const sizes = (params?.resize ?? []).concat([SMALL_SIZE, BIG_SIZE]);
    const builder = [];
    if (params?.convert) {
        builder.push('convert=' + params?.convert);
    }
    for (let i = 0; i < sizes.length; i++) {
        if (sizes[i].width) {
            builder.push(`resize[${i}].Width=${sizes[i].width}`);
        }
        if (sizes[i].height) {
            builder.push(`resize[${i}].Height=${sizes[i].height}`);
        }
    }
    return builder.join('&');
};

interface RemoteFileProps {
    disabled?: boolean;
    fileType: FileType;
    fileId: string;
    localFile?: File;
    fileModel?: FileView;
    convert?: ConvertTypes;
    onRemoved?: (fileId: string) => void;
}

const RemoteFile = (props: RemoteFileProps) => {
    const [remoteFile, setRemoteFile] = useState<FileView | null>(props.fileModel ?? null);
    const classes = useStylesItem();
    const [previewUrl, setPreviewUrl] = useState<string | null>();

    useEffect(
        () => void (props.localFile &&
            getFilePreview(props.localFile).then(setPreviewUrl)),
        [props.localFile]);

    useEffect(() => {
        void (!props.localFile && apiClient
            .callApi<FileView>({ url: `/files/${props.fileId}` })
            .then(setRemoteFile));
    }, [props.localFile, props.fileId]);


    useEffect(() => {
        if (previewUrl) { return; }

        if (remoteFile?.fileType === 'Image') {
            setPreviewUrl(getImageUrl(remoteFile.fileId, props.convert ?? 'JPG', SMALL_SIZE));
        } else if (props.fileType === 'Image') {
            setPreviewUrl(getImageUrl(props.fileId, props.convert ?? 'JPG', SMALL_SIZE));
        }

    }, [remoteFile, props.fileType, previewUrl, props.convert, props.fileId]);

    return (
        <Tooltip
            arrow
            placement='bottom'
            disableFocusListener
            classes={{ tooltip: classes.previewTooltip }}
            title={(
                <Paper className={classes.previewPaper}>
                    {
                        previewUrl
                            ? (<img src={previewUrl} alt='' />)
                            : (props.localFile?.name ?? remoteFile?.fileName)
                    }

                </Paper>
            )}>
            <Chip
                {...fileItemCommonProps}
                classes={{
                    label: classes.label,
                    root: classes.root
                }}
                avatar={previewUrl
                    ? (<Avatar src={previewUrl}><ErrorIcon titleAccess='Failed to load image' /></Avatar>)
                    : undefined}
                label={props.localFile?.name ?? remoteFile?.fileName}
                onDelete={() => props.onRemoved?.(props.fileId)}
                disabled={props.disabled} />
        </Tooltip>
    );
};

interface LocalFileProps {
    disabled?: boolean;
    fileType: FileType;
    imageProcessing?: ImageProcessing;

    localFile: File;
    onFileUploaded?: (model: FileView, localFile: File) => void;
    onUploadCanceled?: (localFile: File) => void;
    onUploadFailed?: (localFile: File) => void;
}

const LocalFile = (props: LocalFileProps) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const uploadCancel = React.useRef<CancellationToken | null>(null);
    const [isErrorUpload, setIsErrorUpload] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const classes = useStylesItem();

    useEffect(
        () => void getFilePreview(props.localFile).then(setPreviewUrl),
        [props.localFile]);

    useEffect(() => {
        if (uploadCancel.current) { return; }
        const data = new FormData();
        data.append('file', props.localFile);

        uploadCancel.current = apiClient.getToken();
        apiClient
            .callApi<FileView>({
                url: props.localFile.type.startsWith('image')
                    ? `/files/image?${getImageProcessingQuery(props.imageProcessing)}`
                    : '/files',
                method: 'POST',
                requestData: data,
                progress: setUploadProgress,
                cancel: uploadCancel.current.token
            })
            .then((d) => props.onFileUploaded?.(d, props.localFile))
            .catch((e) => {
                if (e.isCanceled) {
                    props.onUploadCanceled?.(props.localFile);
                } else {
                    props.onUploadFailed?.(props.localFile);
                    setIsErrorUpload(true);
                }
            })
            .then(() => uploadCancel.current = null);
    }, [props.localFile, props.imageProcessing, setIsErrorUpload,
    props.onFileUploaded, props.onUploadCanceled, props.onUploadFailed]);

    return (
        <Chip
            {...fileItemCommonProps}
            onClick={isErrorUpload ? () => props.onUploadCanceled?.(props.localFile) : undefined}
            avatar={previewUrl ? (<Avatar src={previewUrl} />) : undefined}
            classes={{ label: clsx(isErrorUpload && classes.labelError) }}
            label={isErrorUpload
                ? 'Error Uploading'
                : uploadProgress && uploadProgress < 100
                    ? `Uploading ${uploadProgress}%`
                    : 'Processing...'}
            disabled={props.disabled}
            onDelete={uploadCancel.current?.cancel} />
    );
};

interface FilePickerProps {
    label: string;
    placeholder?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    disabled?: boolean;
    autoFocus?: boolean;

    fileType: FileType;
    imageProcessing?: ImageProcessing;
    multiple?: boolean;

    value?: string | string[] | null;
    onFileUploaded?: (file: FileView, localFile: File) => void;
    onFileRemoved?: (fileId: string) => void;
    onStateChange?: (busy: boolean) => void;
}

export default (props: FilePickerProps) => {
    const classes = useStylesMain();
    // string - is existing file on remote
    // File - is selected file which didn't get to remote yet
    // Tuple - local file that was successfully uploaded now
    const [files, setFiles] = useState<(string | File | [string, File])[]>(
        typeof props.value === 'string' && props.value
            ? [props.value]
            : Array.isArray(props.value)
                ? props.value
                : []);
    const [filesBusy, setFilesBusy] = useState(0);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(
        () => props.onStateChange?.(filesBusy !== 0),
        [filesBusy, props.onStateChange]);

    useEffect(
        () => void (props.autoFocus && inputRef.current?.click()),
        [props.autoFocus, inputRef]);

    const filesSelected = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files?.[0]) { return; }
            const localFiles = (props.multiple
                ? Array.from(e.target.files)
                : [e.target.files?.[0]]);

            if (!localFiles.length) { return; }
            const uploadFiles: File[] = [];
            for (let i = 0; i < localFiles.length; i++) {
                const localFile = localFiles[i];

                if (props.fileType === 'Image' && !localFile.type.startsWith('image/')) {
                    continue;
                } else {
                    uploadFiles.push(localFile);
                }
            }

            setFiles((files) => ([...files, ...uploadFiles]));
            setFilesBusy((filesBusy) => filesBusy + uploadFiles.length);
        }, [props.multiple, props.fileType]);

    return (
        <FormControl disabled={props.disabled} margin='normal' fullWidth>
            <InputLabel htmlFor='filePicker' shrink>{props.label}</InputLabel>
            <div className={classes.inputRoot}>
                <div className={classes.filesContainer}>
                    {!!!files.length && (<label htmlFor='at-file-picker'>Select a file...</label>)}
                    {
                        files.map((file) =>
                            typeof file === 'string'
                                ? (
                                    <RemoteFile
                                        key={file}
                                        fileId={file}
                                        disabled={props.disabled}
                                        fileType={props.fileType}
                                        convert={props.imageProcessing?.convert}
                                        onRemoved={(fileId) => {
                                            setFiles((files) => files.filter((f) => f !== fileId));
                                            props.onFileRemoved?.(fileId);
                                        }}
                                    />
                                )
                                : Array.isArray(file)
                                    ? (
                                        <RemoteFile
                                            key={file[0]}
                                            fileId={file[0]}
                                            localFile={file[1]}
                                            disabled={props.disabled}
                                            fileType={props.fileType}
                                            convert={props.imageProcessing?.convert}
                                            onRemoved={(fileId) => {
                                                setFiles((files) => files
                                                    .filter((f) => !(Array.isArray(f) && f[0] === fileId)));
                                                props.onFileRemoved?.(fileId);
                                            }}
                                        />
                                    )
                                    : (
                                        <LocalFile
                                            key={`${file.name}_${file.size}`}
                                            localFile={file}
                                            disabled={props.disabled}
                                            fileType={props.fileType}
                                            imageProcessing={props.imageProcessing}
                                            onFileUploaded={(model, localFile) => {
                                                setFilesBusy((filesBusy) => filesBusy - 1);
                                                setFiles((files) => {
                                                    files.splice(
                                                        files.findIndex((f) => f === localFile),
                                                        1,
                                                        [model.fileId, localFile]);
                                                    return [...files];
                                                });
                                                props.onFileUploaded?.(model, localFile);
                                            }}
                                            onUploadCanceled={(localFile) => {
                                                setFilesBusy((filesBusy) => filesBusy - 1);
                                                setFiles((files) => files.filter((f) => f !== localFile));
                                            }}
                                            onUploadFailed={() => {
                                                // don't remove from list, so it will show up
                                                // as error for user
                                                setFilesBusy((filesBusy) => filesBusy - 1);
                                            }}
                                        />
                                    ))
                    }
                </div>
                {
                    (!files.length || props.multiple) && (
                        <div className={classes.buttonContainer}>
                            <IconButton
                                id='filePicker'
                                component='label'
                                disabled={props.disabled}
                                className={classes.pickerButton}
                                title='Select File'
                                aria-label='select file'>
                                <ImageIcon />
                                <input
                                    id='at-file-picker'
                                    type='file'
                                    accept={props.fileType === 'Image' ? 'image/*' : undefined}
                                    multiple={props.multiple}
                                    ref={inputRef}
                                    style={{ display: 'none' }}
                                    onChange={filesSelected} />
                            </IconButton >
                        </div>)
                }
            </div>
            {(props.helperText) &&
                <FormHelperText disabled={props.disabled} error={props.error}>
                    {props.helperText}
                </FormHelperText>}
        </FormControl>
    );
};

const useStylesItem = makeStyles((theme) => ({
    root: {
        maxWidth: 150
    },
    label: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    labelError: {
        color: theme.palette.error.main
    },
    previewPaper: {
        width: 150,
        height: 'auto',
        '& img': {
            display: 'block',
            maxWidth: 150
        }
    },
    previewTooltip: {
        padding: 4
    }
}));

const useStylesMain = makeStyles((theme) => ({
    inputRoot: {
        marginTop: 16,
        position: 'relative',
        width: '100%',
        display: 'flex',
        '&:before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '" "',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: `1px solid ${theme.palette.type === 'dark'
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(0, 0, 0, 0.42)'}`,
            pointerEvents: 'none',
        },
        '&:hover:before': {
            borderBottom: `2px solid ${theme.palette.action.active}`
        }
    },
    filesContainer: {
        flex: 1,
        padding: '8px 0 5px',
        '& label': {
            color: theme.palette.text.primary,
            opacity: 0.5,
            fontSize: '1rem',
            fontWeight: 400,
            display: 'block',
            height: 24,
            cursor: 'text'
        },
        '&>div': {
            margin: theme.spacing(0.25)
        }
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    pickerButton: {
        padding: 5
    }
}));
