import create from 'zustand';
import { Cookbook, CookbookModify } from '../models/cooking';
import { RepositoryStore } from '.';
import apiClient from '../services/apiClient';

export interface CookbooksStore extends RepositoryStore<Cookbook, CookbookModify> {
    ensure: () => Promise<void>;
    ensured: boolean;
}

const useCookbooksStore = create<CookbooksStore>((set, get) => ({
    items: [],
    add: async (cookbook: CookbookModify) => {
        const result = await apiClient.callApi<Cookbook>({
            url: '/cookbooks',
            method: 'POST',
            requestData: cookbook
        });
        get().fetch();
        return result;
    },
    fetching: false,
    fetch: async () => {
        if (get().fetching) {
            return;
        }
        set((s) => ({ fetching: true }));

        try {
            const result = await apiClient.callApi<Cookbook[]>({ url: '/cookbooks' });
            set((s) => ({
                fetchedOn: new Date(),
                fetching: false,
                items: result
            }));
        } catch (error) {
            set((s) => ({ fetching: false }));
        }
    },
    ensured: false,
    ensure: async () => {
        if (get().ensured) {
            return;
        }
        set(() => ({ ensured: true }));
        get().fetch();
    }
}));

export { useCookbooksStore };
