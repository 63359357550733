import create from 'zustand';
import createApi from 'zustand/vanilla';

export interface CommonStore extends Record<any, unknown> {
    tasks: number;
    start: () => void;
    end: () => void;
    title: string;
    setTitle: (title?: string) => void;
}

const commonStoreApi = createApi<CommonStore>((set) => ({
    tasks: 0,
    start: () => set((state) => ({
        tasks: state.tasks + 1
    })),
    end: () => set((state) => ({
        tasks: state.tasks - 1
    })),
    title: '',
    setTitle: (title?: string) => set(() => {
        document.title = title ? `${title} | Atrict` : 'Atrict';
        return {
            title: title ?? ''
        };
    })
}));
const useCommonStore = create(commonStoreApi);

export { useCommonStore, commonStoreApi };
