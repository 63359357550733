import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer, Paper, Table, TableHead, TableRow,
    TableCell, TableBody, Chip, Button, Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { InviteView } from '../../models/common';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useUserStore } from '../../stores/userStore';

interface OwnProps {
    invites?: InviteView[];
    ariaLabel: string;
    mainColumn: string;
    onAccept?: (invite: InviteView) => void;
    onReject?: (invite: InviteView) => void;
    onCancel?: (invite: InviteView) => void;
}

export default ({ invites, mainColumn, ariaLabel, ...props }: OwnProps) => {
    const user = useUserStore((state) => state.user);
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            {
                invites?.length === 0
                    ? <Typography variant='body2' style={{ padding: 16 }}>No invites yet. Check back later.</Typography>
                    : (
                        <Table aria-label={ariaLabel}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{mainColumn}</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell className={classes.nowrap}>Sent on</TableCell>
                                    <TableCell align='right'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invites?.map((i) => (
                                        <TableRow key={i.inviteId}>
                                            <TableCell component='th' scope='row'>
                                                {i.recipientUser?.name
                                                    ? `${i.recipientUser?.name} (${i.recipientEmail})`
                                                    : i.recipientEmail}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    variant='outlined'
                                                    icon={
                                                        i.status === 'Waiting' ? (<SwapVerticalCircleIcon />) :
                                                            i.status === 'Rejected' ? (<NotInterestedIcon />) :
                                                                i.status === 'Accepted' ? (<CheckCircleOutlineIcon />) :
                                                                    i.status === 'Canceled'
                                                                        ? (<RemoveCircleOutlineIcon />) : undefined}
                                                    label={
                                                        i.status === 'Waiting' && i.fromUserId === user?.profile.sub
                                                            ? 'Invite sent' : i.status === 'Waiting'
                                                                ? 'Pending Accept' : i.status}
                                                />
                                            </TableCell>
                                            <TableCell className={classes.nowrap}>
                                                {moment(i.addedOn).format('LL')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {
                                                    i.status === 'Waiting' && i.fromUserId === user?.profile.sub
                                                        ? props.onCancel && (
                                                            <Button
                                                                variant='outlined'
                                                                size='small'
                                                                color='secondary'
                                                                onClick={() => props.onCancel?.(i)}>Cancel</Button>
                                                        )
                                                        : i.status === 'Waiting' ? (
                                                            <>
                                                                {
                                                                    props.onAccept && (
                                                                        <Button
                                                                            style={{ marginRight: 10 }}
                                                                            variant='outlined'
                                                                            size='small'
                                                                            color='primary'
                                                                            onClick={() => props.onAccept?.(i)}
                                                                        >Accept</Button>
                                                                    )
                                                                }
                                                                {
                                                                    props.onReject && (
                                                                        <Button
                                                                            variant='outlined'
                                                                            size='small'
                                                                            color='secondary'
                                                                            onClick={() => props.onReject?.(i)}
                                                                        >Reject</Button>
                                                                    )
                                                                }
                                                            </>
                                                        ) : ''
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {
                                    !invites && (
                                        <TableRow key={0}>
                                            <TableCell component='th' scope='row'>
                                                <Skeleton height={20} width={150} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} width={50} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} width={50} />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton height={20} width={50} style={{ float: 'right' }} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    )
            }
        </TableContainer>
    );
};

const useStyles = makeStyles({
    nowrap: {
        whiteSpace: 'nowrap'
    },
});
