import * as React from 'react';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { useHistory, useParams } from 'react-router-dom';
import { useIsMounted } from '../../common/hooks';
import MemoryForm from '../../components/kids/memories/MemoryForm';
import apiClient from '../../services/apiClient';
import { KidMemory } from '../../models/family';

export default () => {
    const addNotification = useNotificationsStore((state) => state.add);
    const history = useHistory();
    const { kidId } = useParams<{ kidId?: string }>();
    const mounted = useIsMounted();
    React.useEffect(
        () => void (mounted.current && !kidId && history.push(`/not-found`)),
        [mounted, kidId, history]);

    return (
        <MemoryForm
            apiCall={(m) => apiClient
                .callApi<KidMemory>({
                    url: `/kids/${kidId}/memories`,
                    method: 'POST',
                    requestData: m
                })}
            error={() => addNotification('There was a problem adding a memory. Please try again later.')}
            success={(m) => mounted.current && history.push(`/kids/${m.kidId}`)}
            onCancel={() => mounted.current && history.push(`/kids/${kidId}`)} />
    );
};
