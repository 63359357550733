import * as React from 'react';
import { makeStyles, Grid, Button, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { FormRender } from '../../../models/common';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Form, Field } from 'react-final-form';
import { submit, compose, maxLength, numberOnly, minNumber } from '../../../common/validation';
import { KidMemoryModify, KidMemory, MEMORY_IMAGE } from '../../../models/family';
import { useState, useMemo } from 'react';
import FilePicker from '../../common/FilePicker';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { WeightPicker, HeightPicker } from '../MetricPickers';
import ReactDOM from 'react-dom';

const textValidators = compose(maxLength(4000));
const weightValidators = compose(numberOnly('Must be a valid weight in kilograms'), minNumber(0.1));
const heightValidators = compose(numberOnly('Must be a valid heigh in centimeters'), minNumber(1));

export enum FormMode {
    Text = 1,
    Metric = 2,
    Image = 4
}

interface OwnProps {
    mode?: FormMode;
    onCancel: () => void;
    onFileAdded?: (fileId: string, local: File) => void;
    onFileDeleted?: (fileId: string) => void;
}
type Props = FormRender<KidMemoryModify, KidMemory> & OwnProps;

export default ({ initialModel, onCancel, mode, onFileAdded, onFileDeleted, actionsRef, ...contract }: Props) => {
    const classes = useStyles();
    const currentMode = useMemo(() => mode ?? FormMode.Text, [mode]);
    const [uploading, setUploading] = useState(false);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Form
                initialValues={initialModel || { occurredOn: moment().toISOString() }}
                keepDirtyOnReinitialize // otherwise will reset form between renders due to initialValues
                mutators={{
                    ...arrayMutators
                }}
                onSubmit={(v: KidMemoryModify) => submit({ submitModel: v, ...contract })}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
                    <form noValidate onSubmit={handleSubmit} autoComplete='off'>
                        <Grid container spacing={4} direction='column'>
                            <Grid item>
                                {
                                    (currentMode & FormMode.Text) === FormMode.Text &&
                                    (
                                        <Field name='text' validate={textValidators}>
                                            {({ input, meta }) => (
                                                <TextField
                                                    margin={currentMode === FormMode.Text ||
                                                        (currentMode & FormMode.Image) === FormMode.Image ? 'none' : 'normal'}
                                                    placeholder='Write a memory...'
                                                    fullWidth
                                                    multiline
                                                    rows={(currentMode & FormMode.Image) === FormMode.Image
                                                        ? undefined : 4}
                                                    autoFocus={currentMode === FormMode.Text}
                                                    error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                    helperText={(meta.dirty && meta.error) || meta.submitError}
                                                    disabled={meta.submitting}
                                                    {...input} />
                                            )}
                                        </Field>
                                    )
                                }
                                {
                                    currentMode === FormMode.Metric && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Field name='metric.weight' validate={weightValidators}>
                                                    {({ input, meta }) => (
                                                        <WeightPicker
                                                            autoFocus={currentMode === FormMode.Metric}
                                                            margin={currentMode === FormMode.Metric ? 'none' : 'normal'}
                                                            placeholder='Report weight'
                                                            error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                            helperText={(meta.dirty && meta.error) || meta.submitError}
                                                            disabled={meta.submitting}
                                                            onChange={input.onChange}
                                                            value={input.value} />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Field name='metric.height' validate={heightValidators}>
                                                    {({ input, meta }) => (
                                                        <HeightPicker
                                                            margin={currentMode === FormMode.Metric ? 'none' : 'normal'}
                                                            placeholder='Report height'
                                                            error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                            helperText={(meta.dirty && meta.error) || meta.submitError}
                                                            disabled={meta.submitting}
                                                            onChange={input.onChange}
                                                            value={input.value} />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    ((currentMode & FormMode.Image) === FormMode.Image
                                        || currentMode === FormMode.Text) &&
                                    (
                                        <FieldArray name='files'>
                                            {({ fields, meta }) => (
                                                <React.Fragment>
                                                    <FilePicker
                                                        label={`Attach photos or drawings`}
                                                        placeholder='Select a file'
                                                        error={(meta.dirty && !!meta.error)
                                                            || !!meta.submitError}
                                                        helperText={(meta.dirty && meta.error)
                                                            || meta.submitError}
                                                        disabled={submitting}
                                                        fileType='Image'
                                                        autoFocus={(currentMode & FormMode.Image)
                                                            === FormMode.Image}
                                                        imageProcessing={MEMORY_IMAGE}
                                                        value={fields.value?.map((f) => f.fileId)}
                                                        onStateChange={setUploading}
                                                        multiple
                                                        onFileUploaded={(f, local) => {
                                                            onFileAdded?.(f.fileId, local);
                                                            fields.push({
                                                                fileId: f.fileId
                                                            });
                                                        }}
                                                        onFileRemoved={(fileId) => {
                                                            onFileDeleted?.(fileId);
                                                            fields.remove(
                                                                fields.value.findIndex((f) => f.fileId === fileId));
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </FieldArray>
                                    )
                                }
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <Field name='occurredOn'>
                                            {({ input, meta }) => (
                                                <DatePicker
                                                    label='Occurred On'
                                                    margin='normal'
                                                    disableFuture
                                                    autoOk
                                                    clearable
                                                    value={input.value ? moment(input.value) : moment()}
                                                    helperText={(meta.dirty && meta.error) || meta.submitError}
                                                    error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                                    fullWidth
                                                    disabled={meta.submitting}
                                                    onChange={(date) => input.onChange(
                                                        date?.toISOString() ?? moment().toISOString())}
                                                    labelFunc={(date) => date?.isSame(moment(), 'day')
                                                        ? 'Today'
                                                        : date?.format('LL') || ''}
                                                    placeholder={`Associate memory with date`} />
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !actionsRef && (
                                    <Grid item container justify='space-between'
                                        alignItems='center' className={classes.actions}>
                                        <Grid item>
                                            <Button
                                                variant='outlined'
                                                size='small'
                                                color='primary'
                                                type='submit'
                                                disabled={
                                                    submitting || pristine || hasValidationErrors || uploading}
                                                startIcon={<SaveIcon />}
                                                tabIndex={0}>Save</Button>
                                            <Button
                                                variant='outlined'
                                                size='small'
                                                color='secondary'
                                                onClick={onCancel}
                                                disabled={submitting || uploading}
                                                tabIndex={1}>Cancel</Button>
                                        </Grid>
                                        <Grid item />
                                    </Grid>
                                )
                            }
                        </Grid>
                        {
                            actionsRef && ReactDOM.createPortal((
                                <>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        onClick={handleSubmit}
                                        disabled={submitting || pristine || hasValidationErrors || uploading}
                                        startIcon={<SaveIcon />}
                                        tabIndex={0}>Save</Button>
                                    <Button
                                        color='default'
                                        onClick={onCancel}
                                        disabled={submitting || uploading}
                                        tabIndex={1}>Cancel</Button>
                                </>
                            ), actionsRef)
                        }
                    </form>
                )} />
        </MuiPickersUtilsProvider>
    );
};


const useStyles = makeStyles(() => ({
    actions: {
        '&>div>*:not(:first-child)': {
            marginLeft: 8
        }
    }
}));
