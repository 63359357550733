import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../common/hooks';

const ViewRecipePage = () => {
    const { recipeId } = useParams<{ recipeId?: string }>();
    useTitle(recipeId ? `View ${recipeId}` : null, [recipeId]);
    return (
        <h1>View {recipeId}</h1>
    );
};

export default ViewRecipePage;
