import axios, { AxiosError, AxiosInstance } from 'axios';
import { AuthService } from './authService';
import { apiHost, environment } from '../common/config';
import { getReturnUrl } from '../common/location';
import { CommonStore, commonStoreApi } from '../stores/commonStore';
import { StoreApi } from 'zustand';
import authService from './authService';

interface ApiRequest<TRequest = any> {
    readonly url: string;
    readonly method?: 'GET' | 'DELETE' | 'POST' | 'PUT';
    readonly requestData?: TRequest;
    readonly responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
    readonly progress?: (percent: number) => void;
    readonly cancel?: Token;
}

interface Token {
    promise: Promise<{ message: string }>;
    reason?: { message: string };
    throwIfRequested(): void;
}
export interface CancellationToken {
    cancel: (message?: string) => void;
    token: Token;
}

export class ApiClient {
    private readonly client: AxiosInstance;

    constructor(
        baseURL: string,
        private readonly authService: AuthService,
        private readonly store: StoreApi<CommonStore>) {

        this.client = axios.create({
            baseURL: apiHost,
        });

        this.enableLogs(environment === 'development');
        this.setupAuth();
        this.setupLoader();
    }

    public callApi = <TResponse = any>(
        request: ApiRequest<any>): Promise<TResponse> => {
        return new Promise((resolve, reject) => {
            this.client
                .request<TResponse>({
                    url: request.url,
                    method: request.method || 'GET',
                    data: request.requestData,
                    responseType: request.responseType || 'json',
                    // otherwise 400 will throw:
                    // https://github.com/axios/axios/issues/41
                    validateStatus: (s) => s < 401,
                    onUploadProgress: request.progress
                        ? (e) => request.progress?.(Math.round((e.loaded * 100) / e.total))
                        : undefined,
                    cancelToken: request.cancel
                })
                .then(response => {
                    if (response?.status >= 200 && response?.status < 400) {
                        resolve(response?.data);
                    }
                    else {
                        reject(response?.data);
                    }
                })
                .catch((error: AxiosError) => axios.isCancel(error)
                    ? reject({ isCanceled: true, error: error.response || error.message })
                    : reject(error.response || error.message));
        });
    }

    public getToken = (): CancellationToken => axios.CancelToken.source();

    private enableLogs = (isDebug: boolean) => {
        if (isDebug) {
            this.client.interceptors.request.use((config) => {
                console.info('Calling API', config.url, config.params);
                return config;
            });
        }

        this.client.interceptors.response.use(
            (response) => {
                if (isDebug) {
                    console.info('Got response from API', response.config.url, response.data);
                }
                return response;
            },
            (error: AxiosError) => {
                if (axios.isCancel(error)) {
                    console.info('Client canceled this request', error.message);
                } else if (!(error.response?.status === 401)) {
                    console.error('There was an error calling API',
                        {
                            url: error.response?.config.url,
                            status: error.response?.status,
                            details: error.message
                        });
                }
                return Promise.reject(error);
            });
    }

    private setupAuth = () => {

        // if user logged-in we'll attach token
        this.client.interceptors.request.use((config) => {
            return this.authService
                .getUser()
                .then((user) => user && !user.expired ? user.access_token : null)
                .then((accessToken) => {
                    if (accessToken) {
                        config.headers.Authorization = `Bearer ${accessToken}`;
                    }
                    return Promise.resolve(config);
                })
                .catch((_) => Promise.reject(config));
        });

        // if response was 401, then initiate auth flow
        this.client.interceptors.response.use(
            undefined,
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    return this.authService
                        .signinRedirect({ state: { returnUrl: getReturnUrl() } })
                        .then(() => Promise.reject(error));
                }
                return Promise.reject(error);
            });
    }

    private setupLoader = () => {
        this.client.interceptors.request.use((c) => {
            this.store.getState().start();
            return c;
        });
        this.client.interceptors.response.use((c) => {
            this.store.getState().end();
            return c;
        }, (e) => {
            this.store.getState().end();
            return Promise.reject(e);
        });
    }
}

const apiClient = new ApiClient(apiHost, authService, commonStoreApi);
export default apiClient;
