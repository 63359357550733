import * as React from 'react';
import FaceIcon from '@material-ui/icons/Face';
import { Kid, getKidImageUrl } from '../../models/family';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Link from '../Link';

const useStyles = makeStyles((theme) =>
    createStyles({
        primary: {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.getContrastText(theme.palette.text.primary)
        },
        secondary: {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.getContrastText(theme.palette.text.secondary)
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            fontSize: theme.typography.subtitle2.fontSize
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            fontSize: theme.typography.h4.fontSize
        },
    }),
);

interface OwnProps {
    kid?: Kid;
    size?: 'small' | 'large';
    color?: 'normal' | 'secondary';
    url?: string;
}

export default ({ kid, size, color, url }: OwnProps) => {
    const classes = useStyles();
    return React.createElement(
        url ? Link : React.Fragment,
        url ? { url } : null,
        kid
            ? (
                <Avatar
                    className={clsx(
                        color === 'secondary' ? classes.secondary : classes.primary,
                        size === 'large' ? classes.large : classes.small)}
                    src={getKidImageUrl(kid)}>
                    {kid.name.substr(0, 1).toLocaleUpperCase()}
                </Avatar>
            )
            : (<FaceIcon color={color === 'secondary' ? 'secondary' : undefined} />)
    );
};
