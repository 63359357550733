import * as React from 'react';
import { TextField, InputAdornment, PropTypes } from '@material-ui/core';
import { useEffect, useState } from 'react';

interface MetricProps {
    label?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    disabled?: boolean;
    onChange?: (grams: number | null) => void;
    value?: number | null;
    margin?: PropTypes.Margin;
    autoFocus?: boolean;
}
const parseHeight = (value: number | null) => (value ?? '') + '';
const convertToHeight = (value: string) => value as any;
export const HeightPicker = ({ onChange, value, margin, autoFocus, ...props }: MetricProps) => {
    const [displayedValue, setDisplayedValue] = useState(parseHeight(value ?? null));
    useEffect(() => setDisplayedValue(parseHeight(value ?? null)), [value]);

    return (
        <TextField
            margin={margin ?? 'normal'}
            fullWidth
            value={displayedValue}
            autoFocus={autoFocus}
            onChange={(e) => {
                setDisplayedValue(e.target.value);
                onChange?.(convertToHeight(e.target.value));
            }}
            inputProps={{
                'aria-label': 'height'
            }}
            InputProps={{
                endAdornment: (<InputAdornment position='end'>cm</InputAdornment>)
            }}
            {...props} />
    );
};

const parseWeight = (value: number | null) => value
    ? typeof value === 'string'
        ? value
        : Math.round(value / 1000) === (value / 1000)
            ? (value / 1000) + ''
            : (value / 1000).toFixed(1)
    : '';

const convertToWeight = (value: string) =>
    (typeof value === 'string' && (value.match(/\./g) ?? []).length > 1) || isNaN(parseFloat(value))
        ? value as any
        : Math.round(parseFloat(value) * 1000);
export const WeightPicker = ({ onChange, value, margin, autoFocus, ...props }: MetricProps) => {
    const [displayedValue, setDisplayedValue] = useState(parseWeight(value ?? null));
    useEffect(() => setDisplayedValue(parseWeight(value ?? null)), [value]);

    return (
        <TextField
            margin={margin ?? 'normal'}
            fullWidth
            value={displayedValue}
            autoFocus={autoFocus}
            onChange={(e) => {
                setDisplayedValue(e.target.value);
                onChange?.(convertToWeight(e.target.value));
            }}
            inputProps={{
                'aria-label': 'weight'
            }}
            InputProps={{
                endAdornment: (<InputAdornment position='end'>Kg</InputAdornment>)
            }}
            {...props} />
    );
};
