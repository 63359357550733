import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';
import { useIsMounted } from '../../common/hooks';
import { useCookbooksStore } from '../../stores/cookbooksStore';

interface OwnProps {
    selectCookbookId?: string;
    onSelected?: (cookbookId: string | null) => void;
}

interface CookbookOptionType {
    inputValue?: string;
    name: string;
    cookbookId?: string;
}
const filter = createFilterOptions<CookbookOptionType>();

type Props = OwnProps & Pick<TextFieldProps, 'error' | 'helperText' | 'disabled' | 'fullWidth' | 'margin' | 'label'>;
export default ({ selectCookbookId, onSelected, ...fieldProps }: Props) => {

    const cookbooks = useCookbooksStore((s) => ({ items: s.items, add: s.add }), shallow);
    const [value, setValue] = useState<CookbookOptionType | null>(
        cookbooks.items.find((c) => c.cookbookId === selectCookbookId) ?? null);
    const isMounted = useIsMounted();
    const [disabled, setDisabled] = useState(false);
    useEffect(
        () => setValue(cookbooks.items.find((c) => c.cookbookId === selectCookbookId) ?? null),
        [cookbooks.items, selectCookbookId]);

    return (
        <Autocomplete
            disabled={disabled}
            value={value}
            onChange={(_, newValue) => {
                const createNew = (name: string) => {
                    setValue({ inputValue: 'Saving...', name });
                    setDisabled(true);
                    cookbooks
                        .add({ name })
                        .then((r) => {
                            if (!isMounted.current) {
                                return;
                            }
                            setValue(r);
                            onSelected?.(r.cookbookId);
                        })
                        .catch(() => setValue(null))
                        .then(() => setDisabled(false));
                };

                if (typeof newValue === 'string') {
                    // create new cookbook when user hit enter in input
                    createNew(newValue);
                } else if (newValue?.inputValue) {
                    // create new cookbook when user select an option from dropdown with "Add ..."
                    createNew(newValue.inputValue);
                } else {
                    // existing cookbook
                    setValue(newValue);
                    onSelected?.(newValue?.cookbookId ?? null);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={cookbooks.items as any as CookbookOptionType[]}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => (
                <TextField {...fieldProps} {...params} placeholder={cookbooks.items.length ? 'Select or create new' : 'New cookbook name'} />
            )}
        />
    );
};
