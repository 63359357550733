import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { PropsWithChildren } from 'react';

export default (props: PropsWithChildren<{ url: string }>) => {
    const history = useHistory();
    return (
        <Link
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                history.push(props.url);
            }}
            href={props.url}>{props.children}</Link>
    );
};
