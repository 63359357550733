import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ActiveNotification } from '../models/common';
import { Snackbar, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useNotificationsStore } from '../stores/notificationsStore';
import shallow from 'zustand/shallow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        close: {
            padding: theme.spacing(0.5),
        },
    }),
);

export default () => {
    const classes = useStyles();
    const { notifications, remove } = useNotificationsStore((state) =>
        ({ notifications: state.notifications, remove: state.remove }), shallow);

    const handleClose = (notification: ActiveNotification, reason?: string) => {
        if (reason === 'clickaway') { return; }
        remove(notification.id);
    };

    return (
        <div className={classes.root} >
            {
                notifications
                    .map((n) => (
                        <Snackbar
                            key={n.id}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={true}
                            onClose={(_, r) => handleClose(n, r)}
                            ContentProps={{
                                'aria-describedby': 'note-id'
                            }}
                            message={<span id='note-id'>{n.text}</span>}
                            action={[
                                <IconButton
                                    key={1}
                                    aria-label='close'
                                    color='inherit'
                                    className={classes.close}
                                    onClick={() => handleClose(n)}>
                                    <CloseIcon />
                                </IconButton>,
                            ]}
                        />
                    ))
            }
        </div>
    );
};
