import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Form, Field } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { required, compose, email } from '../../common/validation';

interface OwnProps {
    title: React.ReactNode;
    content?: React.ReactNode;
    required?: boolean;
    email?: boolean;
    onOK: (value: string | null) => void;
    onCancel: () => void;
    open?: boolean;
    initialValue?: string | null;
    placeholder?: string;
}

export default (props: OwnProps) => {
    const validators = useMemo(
        () => compose(...[
            ...(props.required ? [required()] : []),
            ...(props.email ? [email()] : [])
        ]),
        [props.required, props.email]);

    return (
        <Dialog
            aria-labelledby='prompt-dialog-title'
            disableBackdropClick
            onClose={props.onCancel}
            keepMounted={false}
            open={!!props.open}>
            {
                props.title && (
                    <DialogTitle id='prompt-dialog-title'>{props.title}</DialogTitle>
                )
            }
            <Form
                initialValues={{ value1: props.initialValue }}
                onSubmit={(s) => props.onOK(s.value1)}
                render={({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => (
                    <form noValidate onSubmit={handleSubmit} autoComplete='off'>
                        <DialogContent style={{ width: 340 }}>
                            {props.content}
                            <Field name='value1' validate={validators}>
                                {({ input, meta }) => (
                                    <TextField
                                        margin='none'
                                        fullWidth
                                        autoFocus
                                        placeholder={props.placeholder}
                                        error={(meta.dirty && !!meta.error) || !!meta.submitError}
                                        helperText={(meta.dirty && meta.error) || meta.submitError}
                                        disabled={meta.submitting}
                                        {...input} />
                                )}
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type='submit'
                                disabled={submitting || pristine || hasValidationErrors}
                                color='primary' variant='contained' tabIndex={0}>Ok</Button>
                            <Button onClick={props.onCancel} color='default' tabIndex={1}>Cancel</Button>
                        </DialogActions>
                    </form>
                )} />
        </Dialog>
    );
};
