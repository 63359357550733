import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { getReturnUrl } from './common/location';
import authService, { authRoutes } from './services/authService';

const renderApp = () => {
    ReactDOM.render(<App />, document.getElementById('root'));
};

// force logged in user on application load
if (window.location.pathname !== authRoutes.loginCallback &&
    window.location.pathname !== authRoutes.logoutCallback) {
    authService.getUser()
        .then((u) => {
            if (!u || u.expired !== false) {
                return authService.signinRedirect({ state: { returnUrl: getReturnUrl() } })
                    .catch((e) => {
                        document.getElementById('app')!.innerText = `
                        We are sorry, but something went wrong :(\n\n
                        Please try again later. Meanwhile we logged this error and will handle it ASAP.`;
                    });
            }
            renderApp();
        });
}
else {
    renderApp();
}

// comment in for PWA with service worker in production mode
// registerServiceWorker();
