import * as React from 'react';
import { Paper, IconButton, makeStyles, Theme, Grid } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import MemoryForm, { FormMode } from './MemoryForm';
import { KidMemory } from '../../../models/family';
import { useNotificationsStore } from '../../../stores/notificationsStore';
import apiClient from '../../../services/apiClient';
import MemoryFiles from './MemoryFiles';
import MemorySearchForm from './MemorySearchForm';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface OwnProps {
    kidId: string;
    onAdded?: (memory: KidMemory) => void;
    onSearch?: (searchTerm?: string) => void;
}
export default (props: OwnProps) => {
    const classes = useStyles();
    const addNotification = useNotificationsStore((state) => state.add);
    const [formMode, setFormMode] = useState<FormMode | null>(null);
    const [isSearch, setIsSearch] = useState(false);
    const [addedFiles, setAddedFiles] = useState<{ fileId: string; local: File }[]>([]);
    useEffect(() => {
        // reset when changing from one kid to another
        setFormMode(null);
        setIsSearch(false);
    }, [props.kidId]);
    // reset form when back from search clicked
    useEffect(() => void (!isSearch && props.onSearch?.()), [isSearch, props.onSearch]);
    useEffect(() => void (!formMode && setAddedFiles([])), [formMode]);

    return (
        <Paper elevation={3} className={clsx(classes.root, formMode && classes.active)}>
            <Grid container direction='column'>
                {
                    formMode && !!addedFiles.length && (
                        <Grid item>
                            <MemoryFiles
                                files={addedFiles.filter((_, i) => i < 5)} />
                        </Grid>
                    )
                }
                <Grid item container className={classes.wrapper}>
                    <Grid item className={classes.formContainer}>
                        {
                            formMode
                                ? (
                                    <MemoryForm
                                        apiCall={(m) => apiClient
                                            .callApi<KidMemory>({
                                                url: `/kids/${props.kidId}/memories`,
                                                method: 'POST',
                                                requestData: m
                                            })}
                                        error={() => addNotification('There was a problem adding a memory. Please try again later.')}
                                        success={(m) => {
                                            setFormMode(null);
                                            props.onAdded?.(m);
                                        }}
                                        onCancel={() => setFormMode(null)}
                                        mode={formMode}
                                        onFileAdded={(fileId, local) => setAddedFiles(
                                            (addedFiles) => ([...addedFiles, { fileId, local }]))}
                                        onFileDeleted={(fileId) => setAddedFiles(
                                            (addedFiles) => addedFiles.filter((f) => f.fileId !== fileId))} />
                                )
                                : isSearch
                                    ? (<MemorySearchForm
                                        className={classes.searchInput}
                                        searchSubmitted={(q) => props.onSearch?.(q)} />)
                                    : (
                                        <a href={`/kids/${props.kidId}/memories/new`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFormMode(FormMode.Text);
                                            }}
                                            className={classes.inputClosed}>
                                            Write a memory...
                                        </a>
                                    )
                        }
                    </Grid>
                    {
                        !formMode && !isSearch && (
                            <Grid item>
                                {
                                    props.onAdded && (
                                        <>
                                            <IconButton
                                                className={classes.iconButton}
                                                title='Report weight or height'
                                                aria-label='report weight or height'
                                                onClick={() => setFormMode(FormMode.Metric)}>
                                                <AssessmentIcon />
                                            </IconButton>
                                            <IconButton
                                                className={classes.iconButton}
                                                title='Add image/s to a new memory'
                                                aria-label='upload images'
                                                onClick={() => setFormMode(FormMode.Text | FormMode.Image)}>
                                                <ImageIcon />
                                            </IconButton>
                                        </>
                                    )
                                }
                                <IconButton
                                    className={classes.iconButton}
                                    title='Search Memories'
                                    aria-label='search memories'
                                    onClick={() => setIsSearch(true)}>
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        )}
                    {
                        isSearch && props.onAdded && (
                            <Grid item>
                                <IconButton
                                    title='Cancel Search'
                                    className={classes.iconButton}
                                    onClick={() => setIsSearch(false)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>)
                    }
                </Grid >
            </Grid >
        </Paper >
    );
};


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '80%'
    },
    active: {
        overflow: 'hidden',
        marginBottom: 15
    },
    wrapper: {
        padding: '2px 4px',
    },
    formContainer: {
        flex: 1,
        padding: '6px 8px'
    },
    inputClosed: {
        flex: 1,
        display: 'block',
        cursor: 'text',
        color: theme.palette.text.primary,
        opacity: 0.5,
        fontSize: '16px',
        padding: '6px 0',
        lineHeight: '19.0016px',
        textDecoration: 'none',
        '& textarea': {
            height: 19,
            transition: theme.transitions.create('height', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        }
    },
    mainActionContainer: {
        paddingLeft: 8
    },
    iconButton: {
        padding: 10,
    },
    searchInput: {
        padding: '6px 0',
        height: 'auto'
    }
}));
