import React, { useMemo } from 'react';
import {
    Drawer, IconButton, Divider, Theme, WithWidth, withWidth, isWidthDown,
    WithStyles, createStyles, withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import Profile from './Profile';
import { useCommonStore } from '../../stores/commonStore';
import Actions from './Actions';
import NavMenu from './NavMenu';

interface OwnProps {
    drawerOpened: boolean;
    openClick: () => void;
    closeClick: () => void;
}

type Props = WithWidth & OwnProps & WithStyles<typeof styles>;
const SideBar = ({ drawerOpened, openClick, closeClick, width, classes }: Props) => {
    const title = useCommonStore((state) => state.title);
    const temporaryDrawer = useMemo(() => isWidthDown('sm', width), [width]);

    return (
        <Drawer variant={temporaryDrawer ? 'temporary' : 'permanent'}
            classes={{
                paper: clsx(classes.drawerPaper, !drawerOpened && classes.drawerPaperClose, drawerOpened && 'a-drawer-opened'),
            }}
            open={drawerOpened}
            ModalProps={{ keepMounted: true }}
            onClose={closeClick}>
            <div className={classes.toolbar}>
                <IconButton onClick={drawerOpened ? closeClick : openClick}>
                    <MenuIcon />
                </IconButton>
                <div className={classes.title}>
                    {title}
                </div>
            </div>
            <Divider />
            <Actions onClick={() => temporaryDrawer && closeClick()} homeOnly />
            <NavMenu onClick={() => temporaryDrawer && closeClick()} drawerOpened={drawerOpened} />
            <div className={classes.bottomItem}>
                <Divider />
                <Profile onClick={() => temporaryDrawer && closeClick()} />
            </div>
        </Drawer>
    );
};

const styles = (theme: Theme) => createStyles({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 4px',
        ...theme.mixins.toolbar,
    },
    title:
    {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: 220,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7)
    },
    bottomItem: {
        marginTop: 'auto'
    }
});

export default withWidth()(withStyles(styles)(SideBar));
