import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useTitle } from '../common/hooks';

interface OwnProps {
    title?: string;
    layout?: React.ComponentType<any>;
}

type Props = RouteProps & OwnProps;

const Page = (props: Props) => {
    const { title, layout, ...rest } = props;
    useTitle(title ?? null, [title]);

    return layout
        ? React.createElement(layout, {}, (<Route {...rest} />))
        : <Route {...rest} />;
};

export default Page;
