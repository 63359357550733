import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

interface OwnProps {
    defaultExpanded: boolean;
    onClose?: () => void;
    title?: string;
}

type Props = React.PropsWithChildren<OwnProps>;
export default ({ defaultExpanded, onClose, title, children }: Props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(defaultExpanded);

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} elevation={0}>
                <AccordionSummary classes={{
                    content: classes.summaryContent,
                    root: classes.summaryRoot
                }}>
                    <Grid container justify='space-between' alignItems='center'>
                        <Typography>{title}</Typography>
                        {expanded
                            ? (<IconButton aria-label='clear' title='Clear'
                                onClick={() => {
                                    setExpanded(false);
                                    onClose?.();
                                }}>
                                <DeleteIcon />
                            </IconButton>)
                            : (<IconButton aria-label='add' title='Add'
                                onClick={() => setExpanded(true)}>
                                <AddIcon />
                            </IconButton>)}
                    </Grid>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        margin: '16px 0 8px'
    },
    summaryRoot: {
        cursor: 'default',
        '&>*': {
            cursor: 'default'

        }
    },
    summaryContent: {
        margin: 0,
        '&.Mui-expanded': {
            margin: 0,
            minHeight: 'auto'
        },
        '& button': {
            padding: 5
        }
    }
}));
