import { useState, useCallback } from 'react';
import { useEffect, DependencyList, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useCommonStore } from '../stores/commonStore';

/**
 * Routes client to not-found page.
 * @param notFoundCondition If returns true will route to not-found.
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useNotFound = (
    notFoundCondition: () => boolean,
    deps?: DependencyList) => {
    const history = useHistory();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted.current && notFoundCondition()) {
            history.push('/not-found');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notFoundCondition, ...(deps || [])]);
};

/**
 * Returns whether component mounted Ref.
 */
export const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; };
    });
    return isMounted;
};

/**
 * Sets document title
 * @param title Title or `null` which ignores update.
 * @param deps If present, effect will only activate if the values in the list change.
 */
export const useTitle = (
    title: string | null,
    deps?: DependencyList) => {
    const setTitle = useCommonStore((state) => state.setTitle);

    useEffect(
        () => void (title !== null && setTitle(title)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title, ...(deps || [])]);
};


export const useScroll = (target = document.querySelector('main')):
    [boolean, () => void] => {
    if (!target) { throw new Error('No element found to track scroll'); }
    const [reachedBottom, setReachedBottom] = useState(false);
    const reset = useCallback(() => target.scrollTo(0, 0), [target]);

    useEffect(() => {
        const onScroll = (e: any) => {
            if (e?.target) {
                const scrollTop = e.target.scrollTop;
                const scrollHeight = e.target.scrollHeight;
                const height = e.target.getBoundingClientRect().height;

                setReachedBottom(!!scrollHeight && (scrollTop + height) >= scrollHeight);
            } else {
                console.error('Cannot track scroll for the element', e);
            }
        };
        target.addEventListener('scroll', onScroll);
        return () => target.removeEventListener('scroll', onScroll);
    }, [target]);
    return [reachedBottom, reset];
};

export const useQuery = () => new URLSearchParams(useLocation().search);
