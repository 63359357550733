import * as React from 'react';
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/FavoriteBorderOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Skeleton from '@material-ui/lab/Skeleton';
import { KidMemory, Kid } from '../../../models/family';
import moment from 'moment';
import MemoryFiles, { MediaFile } from './MemoryFiles';
import { friendlyAge } from '../../../common/humanize';
import Qty from 'js-quantities';

interface OwnProps {
    memory: KidMemory;
    kid: Kid;
    onLikeClick?: (memory: KidMemory) => void;
    onSettingsClick?: (memory: KidMemory, el: HTMLElement) => void;
    onAlbumClick: (memory: KidMemory, zoomFile?: MediaFile) => void;
}

export default ({ memory, kid, onLikeClick, onSettingsClick, onAlbumClick }: OwnProps) => {
    const classes = useStyles();

    return (
        <Grid container direction='column'>
            <Grid item>
                <Paper className={classes.paper}>
                    {!!memory.files.length && (
                        <MemoryFiles
                            onImageClick={(file) => onAlbumClick(memory, file)}
                            files={memory.files.filter((_, i) => i < 5)} />
                    )}
                    {memory.text && <div className={classes.content}>{memory.text}</div>}
                    {memory.metric && (
                        <div className={classes.content}>
                            {memory.metric.height && (
                                <span>Height: {new Qty(memory.metric.height, 'cm').toString(
                                    memory.metric.height < 100 ? undefined : 'm')}&nbsp;</span>)}
                            {memory.metric.weight && (
                                <span>Weight: {new Qty(memory.metric.weight, 'gram').toString('kg')}</span>)}
                        </div>)}
                    <div className={classes.actions}>
                        {
                            memory.files.length > 4 && onAlbumClick && (
                                <IconButton
                                    aria-label='view all images'
                                    title='View All Images'
                                    onClick={() => onAlbumClick(memory)}>
                                    <PhotoLibraryIcon fontSize='small' />
                                </IconButton>
                            )
                        }
                        {
                            onLikeClick && (
                                <IconButton aria-label='add to favorites' onClick={() => onLikeClick(memory)}>
                                    <FavoriteIcon fontSize='small' />
                                </IconButton>
                            )
                        }
                        {
                            onSettingsClick && (
                                <IconButton
                                    aria-label='memory settings'
                                    aria-haspopup='true'
                                    onClick={(e) => onSettingsClick(memory, e.currentTarget)}>
                                    <MoreVertIcon fontSize='small' />
                                </IconButton>
                            )
                        }
                    </div>
                </Paper>
            </Grid>
            {
                memory.occurredOn && (
                    <Grid item container justify='space-between'>
                        <Typography variant='caption' color='textSecondary'>
                            {
                                kid.birthDate && memory.occurredOn
                                    ? friendlyAge(kid.birthDate, memory.occurredOn, true)
                                    : ''
                            }
                        </Typography>
                        <Typography variant='caption' color='textSecondary'>
                            {moment(memory.occurredOn).format('LL')}
                        </Typography>
                    </Grid>
                )
            }
        </Grid>
    );
};

export const MemoryInlineLoading = () => {
    const classes = useStyles();

    return (
        <Grid container direction='column'>
            <Grid item>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        <Skeleton width='30%' />
                        <Skeleton width='100%' />
                        <Skeleton width='60%' />
                    </div>
                </Paper>
            </Grid>
            <Grid item container justify='space-between'>
                <Typography variant='caption' color='textSecondary'>Loading...</Typography>
            </Grid>
        </Grid>
    );
};


const useStyles = makeStyles((theme) => ({
    paper: {
        overflow: 'hidden'
    },
    content: {
        padding: '6px 16px',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
    },
    actions: {
        padding: '0 6px 6px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '&:empty': {
            display: 'none'
        }
    }
}));
