import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIsMounted, useTitle } from '../../common/hooks';
import { useEffect, useState } from 'react';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { Recipe } from '../../models/cooking';
import apiClient from '../../services/apiClient';
import RecipeForm from '../../components/cooking/RecipeForm';

const EditRecipePage = () => {
    const { recipeId, returnUrl } = useParams<{ recipeId?: string; returnUrl?: string; }>();
    const [recipe, setRecipe] = useState<Recipe | null>(null);
    const addNotification = useNotificationsStore((state) => state.add);
    const history = useHistory();
    const mounted = useIsMounted();

    useEffect(
        () => {
            recipeId && !recipe && apiClient
                .callApi<Recipe>({ url: `/recipes/${recipeId}` })
                .then((r) => setRecipe(r))
                .catch((e) => {
                    if (e.status === 404) {
                        mounted.current && history.push(`/recipes`);
                    } else {
                        addNotification('Failed to get recipe. Please try again later.');
                    }
                });
        },
        [recipeId, recipe, addNotification, history, mounted]);

    useTitle(recipe ? `Edit ${recipe!.title}` : null, [recipe]);

    return (
        recipe && <RecipeForm
            initialModel={recipe}
            apiCall={(m) => apiClient
                .callApi<Recipe>({
                    url: `/recipes/${recipe.recipeId}`,
                    method: 'PUT',
                    requestData: m
                })}
            error={() => addNotification('There was a problem modifying this recipe. Please try again later.')}
            success={(m) => mounted.current && history.push(returnUrl ?? `/recipes/${m.recipeId}`)}
        />
    );
};



export default EditRecipePage;
