import * as React from 'react';
import { Paper, makeStyles, Theme, Grid } from '@material-ui/core';
import { useState, useMemo } from 'react';
import clsx from 'clsx';
import MemoryForm, { FormMode } from './MemoryForm';
import { KidMemory } from '../../../models/family';
import { useNotificationsStore } from '../../../stores/notificationsStore';
import apiClient from '../../../services/apiClient';
import MemoryFiles from './MemoryFiles';

interface OwnProps {
    model: KidMemory;
    onUpdated?: (memoryUpdated: KidMemory) => void;
    onCancel?: () => void;
}
export default ({ model, onUpdated, onCancel }: OwnProps) => {
    const classes = useStyles();
    const addNotification = useNotificationsStore((state) => state.add);
    const formMode = useMemo(() => model.metric?.height || model.metric?.weight
        ? FormMode.Metric
        : FormMode.Text, [model]);
    const [addedFiles, setAddedFiles] = useState<{ fileId: string; local?: File }[]>(model.files);

    return (
        <Paper elevation={3} className={clsx(classes.root, formMode && classes.active)}>
            <Grid container direction='column'>
                {
                    formMode && !!addedFiles.length && (
                        <Grid item>
                            <MemoryFiles
                                files={addedFiles.filter((_, i) => i < 5)} />
                        </Grid>
                    )
                }
                <Grid item container className={classes.wrapper}>
                    <Grid item className={classes.formContainer}>
                        <MemoryForm
                            initialModel={model}
                            apiCall={(m) => apiClient
                                .callApi<KidMemory>({
                                    url: `/memories/${model.memoryId}`,
                                    method: 'PUT',
                                    requestData: m
                                })}
                            error={() => addNotification('There was a problem modifying this memory. Please try again later.')}
                            success={(m) => onUpdated?.(m)}
                            onCancel={() => onCancel?.()}
                            mode={formMode}
                            onFileAdded={(fileId, local) => setAddedFiles(
                                (addedFiles) => ([...addedFiles, { fileId, local }]))}
                            onFileDeleted={(fileId) => setAddedFiles(
                                (addedFiles) => addedFiles.filter((f) => f.fileId !== fileId))} />
                    </Grid>
                </Grid >
            </Grid >
        </Paper >
    );
};


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '80%'
    },
    active: {
        overflow: 'hidden',
        marginBottom: 15
    },
    wrapper: {
        padding: '2px 4px',
    },
    formContainer: {
        flex: 1,
        padding: '6px 8px'
    },
    inputClosed: {
        flex: 1,
        display: 'block',
        cursor: 'text',
        color: theme.palette.text.primary,
        opacity: 0.5,
        fontSize: '16px',
        padding: '6px 0',
        lineHeight: '19.0016px',
        textDecoration: 'none',
        '& textarea': {
            height: 19,
            transition: theme.transitions.create('height', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })
        }
    },
    mainActionContainer: {
        paddingLeft: 8
    },
    iconButton: {
        padding: 10,
    }
}));
