import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { RecipeSummary } from '../../models/cooking';
import clsx from 'clsx';
import { CardActionArea, Chip, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { fallBackImage } from '../../common';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '../Link';

interface OwnProps {
    recipe: RecipeSummary;
    className?: string;
    showDescription?: boolean;
    onMenuClick?: (element: HTMLElement) => void;
}
export default ({ recipe, className, showDescription, onMenuClick }: OwnProps) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Card className={clsx(classes.root, className)}>
            <CardHeader
                action={
                    <IconButton
                        aria-controls='recipe-settings-menu'
                        aria-haspopup='true'
                        aria-label='recipe settings'
                        onClick={(e) => onMenuClick?.(e.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                }
                classes={{ title: classes.title }}
                title={<Link url={`/recipes/${recipe.recipeId}`}>{recipe.title}</Link>}
                subheader={!recipe.ingredientsCount || !recipe.stepsCount
                    ? moment(recipe.addedOn).fromNow()
                    : `Steps: ${recipe.stepsCount} Ingredients: ${recipe.ingredientsCount}`} />

            <CardActionArea
                onClick={(e) => {
                    e.preventDefault();
                    history.push(e.currentTarget.getAttribute('href')!);
                }}
                href={`/recipes/${recipe.recipeId}`}>
                <CardMedia
                    className={classes.media}
                    image={fallBackImage(
                        !!recipe.mainImageFileId,
                        `${recipe.mainImageFileId}/512x.jpg`,
                        parseInt(recipe.recipeId.match(/\d/)?.[0] || '0'))}
                    title={recipe.title} />
                {
                    showDescription && recipe.description && <React.Fragment>
                        <CardContent>
                            <Typography variant='body2' color='textSecondary' component='p'>
                                {recipe.description}
                            </Typography>
                        </CardContent>
                        <Divider />
                    </React.Fragment>
                }
                {
                    recipe.tags && recipe.tags.length > 0 && <CardContent>
                        <div className={classes.tags}>
                            {
                                recipe.tags.map((r) => (<Chip label={r} key={r} />))
                            }
                        </div>
                    </CardContent>
                }
            </CardActionArea>
        </Card>
    );
};

export const RecipeSummaryEmptyCard = ({ className }: Omit<OwnProps, 'recipe'>) => {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)}>
            <CardActionArea>
                <CardHeader
                    classes={{ title: classes.title }}
                    title={<Skeleton animation='wave' height={32} width='80%' style={{ marginBottom: 6 }} />}
                    subheader={<Skeleton animation='wave' height={24} width='40%' />} />
                <Skeleton animation='wave' variant='rect' className={classes.media} />
                <CardContent>
                    <Skeleton animation='wave' height={32} width='30%' />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 345,
        },
        title: {
            textOverflow: 'ellipsis',
            maxWidth: 273,
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        avatar: {
            backgroundColor: red[500],
        },
        tags: {
            '& > *': {
                margin: theme.spacing(0.5),
            }
        }
    }),
);
