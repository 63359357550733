import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Typography
} from '@material-ui/core';
import { Kid, KidRelation } from '../../models/family';
import AddIcon from '@material-ui/icons/Add';
import apiClient from '../../services/apiClient';
import { useIsMounted } from '../../common/hooks';
import { Skeleton } from '@material-ui/lab';
import { useUserStore } from '../../stores/userStore';
import PromptDialog from '../common/PromptDialog';
import { InviteView, Paged } from '../../models/common';
import { useNotificationsStore } from '../../stores/notificationsStore';

interface OwnProps {
    model: Kid;
}

export default ({ model }: OwnProps) => {
    const classes = useStyles();
    const [relations, setRelations] = useState<KidRelation[] | null>(null);
    const [invites, setInvites] = useState<InviteView[]>([]);
    const user = useUserStore((state) => state.user);
    const isMounted = useIsMounted();
    const [inviteOpened, setInviteOpened] = useState(false);
    const [inviteSending, setInviteSending] = useState(false);
    const addNotification = useNotificationsStore((state) => state.add);

    const fetchRelations = useCallback(
        () => void apiClient
            .callApi<KidRelation[]>({
                url: `/kids/${model.kidId}/relations`
            })
            .then((r) => isMounted.current && setRelations(r))
            .catch((e) => {
                setRelations([]);
                console.error('Something went wrong fetching relations', e);
            }),
        [isMounted, model]);

    const fetchInvites = useCallback(
        () => void apiClient
            .callApi<Paged<InviteView>>({
                url: `/invites?type=Kid&status=Waiting&status=Rejected&limit=1000`
            })
            .then((r) => isMounted.current && setInvites(
                r.items.filter((i) => i.entityId === model.kidId))),
        [isMounted, model]);

    useEffect(fetchRelations, [fetchRelations]);
    useEffect(fetchInvites, [fetchInvites]);

    const sendInvite = useCallback(
        (value: string | null) => {
            setInviteSending(true);
            setInviteOpened(false);

            apiClient
                .callApi<InviteView>({
                    url: `/invites`,
                    method: 'POST',
                    requestData: {
                        entityType: 'Kid',
                        entityId: model.kidId,
                        recipientEmail: value
                    }
                })
                .then((r) => isMounted.current && setInvites((o) => [...o, r]))
                .catch((e) => addNotification(`Something went wrong and we couldn't send this invite`))
                .then(() => isMounted.current && setInviteSending(false));
        },
        [model, isMounted, addNotification]);

    const cancelInvite = useCallback(
        (inviteId: string) => {
            setInvites((o) => o.filter((f) => f.inviteId !== inviteId));
            apiClient
                .callApi<InviteView>({
                    url: `/invites/${inviteId}/cancel`,
                    method: 'POST'
                })
                .then(() => {
                    fetchRelations();
                    fetchInvites();
                });

        },
        [fetchRelations, fetchInvites]);

    return (
        <div>
            <Typography variant='h6'>Permissions</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='permissions'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align='right'>Permission</TableCell>
                            <TableCell align='right'>
                                <Button
                                    variant='outlined'
                                    color='default'
                                    disabled={inviteOpened || inviteSending}
                                    onClick={() => setInviteOpened(true)}
                                    startIcon={<AddIcon />}>Invite</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            relations?.map((r) => (
                                <TableRow key={r.userId}>
                                    <TableCell component='th' scope='row'>
                                        {user?.profile.sub === r.userId
                                            ? `${user?.profile.name} (you)`
                                            : (r.name || r.email || '(private)')}
                                    </TableCell>
                                    <TableCell align='right'>{r.type || ''}</TableCell>
                                    <TableCell align='right'>
                                        {
                                            r.type !== 'Owner' && (
                                                <Button
                                                    variant='text'
                                                    color='secondary'>Delete</Button>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            invites.map((i) => (
                                <TableRow key={i.inviteId}>
                                    <TableCell component='th' scope='row'>
                                        {i.recipientEmail}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {i.status === 'Waiting' ? 'Invite sent' : i.status}
                                    </TableCell>
                                    <TableCell align='right'>
                                        {
                                            i.status === 'Waiting'
                                                ? (
                                                    <Button
                                                        variant='text'
                                                        color='secondary'
                                                        onClick={() => cancelInvite(i.inviteId)}>Cancel</Button>
                                                )
                                                : ''
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        {
                            (!relations || inviteSending) && (
                                <TableRow key={0}>
                                    <TableCell component='th' scope='row'>
                                        <Skeleton height={20} width={150} />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton height={20} width={50} style={{ float: 'right' }} />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <PromptDialog
                open={inviteOpened}
                title={`Invite to view ${model.name}`}
                initialValue={null}
                required
                email
                placeholder={'example@example.com'}
                onCancel={() => setInviteOpened(false)}
                onOK={sendInvite} />
        </div>
    );
};

const useStyles = makeStyles({
    table: {
    },
});
