import { withStyles, WithStyles, Container, createStyles, Theme } from '@material-ui/core';
import React, { PropsWithChildren, useState, Fragment } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import withWidth, { WithWidth, isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import Spinner from '../Spinner';

type Props = WithWidth & WithStyles<typeof styles>;
const Layout = ({ classes, children, width }: PropsWithChildren<Props>) => {
    const [drawerOpened, setDrawerOpened] = useState(isWidthUp('lg', width));
    const topBarShown = isWidthDown('sm', width);

    return (
        <div className={classes.root}>
            {
                topBarShown &&
                <Topbar
                    drawerOpened={drawerOpened}
                    toggleClick={() => setDrawerOpened(!drawerOpened)} />}
            <Sidebar
                drawerOpened={drawerOpened}
                openClick={() => setDrawerOpened(true)}
                closeClick={() => setDrawerOpened(false)} />

            <main className={classes.content}>
                {topBarShown &&
                    <div className={classes.appBarSpacer} />}
                <Spinner />
                <Container className={classes.container} maxWidth='lg'>
                    <Fragment>
                        {children}
                    </Fragment>
                </Container>
            </main>
        </div >
    );
};

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        position: 'relative',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minHeight: '100vh',
    },
    fixedHeight: {
        height: 240,
    },
});

export default withWidth()(withStyles(styles)(Layout));
